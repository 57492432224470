import { useDebouncedValue } from "@axazara/raiton-utils";
import React from "react";
import { PaginatedUsers } from "utilities";
import { useGetUsers } from ".";
import { useDynamicURLSearchParams } from "@/hooks";

export function useUsers({ initialData }: { initialData: PaginatedUsers }) {
  const stateData = [
    { label: "All", value: "all", color: "bg-neutral-50" },
    { label: "Active", value: "active", color: "bg-success-50" },
    { label: "Inactive", value: "inactive", color: "bg-critical-50" },
  ];

  const { params: urlParams, setParams: setUrlParams } =
    useDynamicURLSearchParams<{
      search: string;
      page: string;
      status: string;
    }>({ search: "", page: "1", status: "all" });

  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const [opened, setOpened] = React.useState(false);

  const [state, setState] = React.useState<any | null>(
    stateData.find((state) => state.value === urlParams.status) || stateData[0],
  );

  const { data: users, isLoading } = useGetUsers({
    page,
    search: debouncedSearch,
    initialData,
    status: state?.value || "all",
  });

  return {
    users,
    isLoading,

    page,
    setPage,
    searchQuery,
    setSearchQuery,
    debouncedSearch,
    setState,
    opened,
    setUrlParams,
    setOpened,
    stateData,
    state,
  };
}
