import React from "react";
import { Box, Code, Text } from "@axazara/raiton-atoms";
import { ProfileCircleFill } from "@axazara/raiton-icons";
import { Activity } from "@/models";
import { formatDate } from "utilities";

interface ActivityDetailsModalProps {
  activity: Activity;
}

interface ActivityDetailProps {
  title: string;
  content: React.ReactNode;
}

function ActivityDetail({ title, content }: ActivityDetailProps) {
  return (
    <Box className="flex flex-col space-y-2">
      <Text component="span" className="text-neutral-50 text-p-01-semibold">
        {title}
      </Text>
      <Text component="span" className="text-p-01">
        {content}
      </Text>
    </Box>
  );
}

export function ActivityDetailsModal({ activity }: ActivityDetailsModalProps) {
  return (
    <Box className="px-8 pb-8">
      <Box className="flex justify-between mb-12">
        <ActivityDetail title="ID" content={activity.id} />
        <ActivityDetail
          title="Date"
          content={formatDate(activity.updated_at, "en")}
        />
      </Box>
      <Text
        component="span"
        className="text-neutral-50 text-p-01-semibold mb-2"
      >
        Performed by
      </Text>
      <Box className="flex items-center mb-12">
        <ProfileCircleFill className="w-16 h-16" />
        <Box className="ml-4 space-y-1.5 flex flex-col">
          <Text component="span" className="text-p-01-semibold">
            {activity.staff.full_name}
          </Text>
          <Text component="span" className="text-p-01-medium text-neutral-40">
            {activity.staff.email}
          </Text>
        </Box>
      </Box>
      <Box className="flex justify-between mb-12">
        <ActivityDetail title="Action" content={activity.action} />
        <ActivityDetail title="IP Address" content={activity.ip} />
      </Box>
      <ActivityDetail title="User Agent" content={activity.user_agent} />
      <Box className="flex flex-col justify-between border-b border-neutral">
        <Text className="text-neutral-30 md:text-p-02-medium text-p-01-medium mb-4">
          Data
        </Text>
        <Code block>{JSON.stringify(activity.data, null, 2)}</Code>
      </Box>
    </Box>
  );
}
