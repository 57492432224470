export enum Permissions {
  CREATE_SERVERS = "create.servers",
  READ_SERVERS = "read.servers",
  UPDATE_SERVERS = "update.servers",
  DELETE_SERVERS = "delete.servers",
  CREATE_TEMPLATES = "create.templates",
  READ_TEMPLATES = "read.templates",
  UPDATE_TEMPLATES = "update.templates",
  DELETE_TEMPLATES = "delete.templates",
  READ_TEAM_MEMBERS = "read.team_members",
  ADD_TEAM_MEMBERS = "add.team_members",
  DELETE_TEAM_MEMBERS = "delete.team_members",
  UPDATE_TEAM_MEMBERS = "update.team_members",
  READ_API_KEY = "read.api_key",
  REGENERATE_API_KEY = "regenerate.api_key",
  UPDATE_APPS = "update.apps",
  UPDATE_APP_SETTINGS = "update.app_settings",
  READ_APP_SETTINGS = "read.app_settings",
  DELETE_APPS = "delete.apps",
  READ_STATS = "read.stats",
}

export enum StaffPermissions {
  CREATE_STAFFS = "create.staffs",
  UPDATE_STAFFS = "update.staffs",
  READ_STAFFS = "read.staffs",
  CREATE_SERVERS = "create.servers",
  READ_SERVERS = "read.servers",
  UPDATE_SERVERS = "update.servers",
  DELETE_SERVERS = "delete.servers",
  CREATE_TEMPLATES = "create.templates",
  READ_TEMPLATES = "read.templates",
  UPDATE_TEMPLATES = "update.templates",
  DELETE_TEMPLATES = "delete.templates",
  READ_TEAM_MEMBERS = "read.team_members",
  ADD_TEAM_MEMBERS = "add.team_members",
  DELETE_TEAM_MEMBERS = "delete.team_members",
  UPDATE_TEAM_MEMBERS = "update.team_members",
  READ_API_KEY = "read.api_key",
  REGENERATE_API_KEY = "regenerate.api_key",
  UPDATE_APPS = "update.apps",
  UPDATE_APP_SETTINGS = "update.app_settings",
  READ_APP_SETTINGS = "read.app_settings",
  DELETE_APPS = "delete.apps",
  READ_STATS = "read.stats",
}
