import { Avatar, Box, Tag, Text, Tooltip } from "@axazara/raiton-atoms";
import {
  ArrowUpOutline,
  ArrowDown1Outline,
  TickCircleFill,
  CloseCircleFill,
  TickCircleOutline,
  CloseCircleOutline,
  CopySuccessOutline,
  CopyOutline,
} from "@axazara/raiton-icons";
import { useClipboard } from "@axazara/raiton-utils";
import { CardTransaction, formatDate } from "utilities";

export function CardTransactionsDetailsModal({
  transaction,
}: {
  transaction: CardTransaction;
}) {
  return (
    <Box className="space-y-4 mt-8 font-poppins text-neutral-40">
      <Box className="border border-neutral-80 p-4 rounded-xl space-y-4">
        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Amount</Text>
          <Text className="text-p-01-medium">
            {transaction.amount_formatted}
          </Text>
        </Box>
        <CopyableInformationItem
          label="Reference id"
          value={transaction.reference_id}
        />
        <CopyableInformationItem
          label="External id"
          value={transaction.external_id}
        />

        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Type</Text>
          {transaction.type === "debit" && (
            <Tag
              size="lg"
              leftSection={<ArrowUpOutline className="w-4 h-4" />}
              className="text-critical-40 bg-critical"
            >
              Debit
            </Tag>
          )}
          {transaction.type === "credit" && (
            <Tag
              size="lg"
              leftSection={<ArrowDown1Outline className="w-4 h-4" />}
              className="text-success-40 bg-success"
            >
              Credit
            </Tag>
          )}
        </Box>

        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Status</Text>
          {transaction.status === "success" && (
            <Tag
              className="text-p-01-semibold normal-case bg-success border border-success-90 py-3 pl-1 pr-2"
              size="lg"
              leftSection={
                <TickCircleOutline className="w-4 h-4 text-success-30" />
              }
            >
              <Text
                component="span"
                className="text-success-30 text-p-01-semibold ml-0.5"
              >
                Success
              </Text>
            </Tag>
          )}
          {transaction.status === "failed" && (
            <Tag
              className="text-p-01-semibold normal-case bg-critical border border-critical-90 py-3 pl-1 pr-2"
              size="lg"
              leftSection={
                <CloseCircleOutline className="w-4 h-4 text-critical-40" />
              }
            >
              <Text
                component="span"
                className="text-critical-30 text-p-01-semibold ml-0.5"
              >
                Failed
              </Text>
            </Tag>
          )}
        </Box>

        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Category</Text>
          <Text className="text-p-01-medium">{transaction.category}</Text>
        </Box>
        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Currency</Text>
          <Text className="text-p-01-medium">{transaction.currency}</Text>
        </Box>
        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Description</Text>
          <Text className="text-p-01-medium">{transaction.description}</Text>
        </Box>

        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">group</Text>
          <Text className="text-p-01-medium">{transaction.group}</Text>
        </Box>
        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Created at</Text>
          <Text className="text-p-01-medium">
            {formatDate(transaction.created_at, "en", "D MMM YYYY, HH:mm:ss")}
          </Text>
        </Box>
        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">processed at</Text>
          <Text className="text-p-01-medium">
            {formatDate(transaction.processed_at, "en", "D MMM YYYY, HH:mm:ss")}
          </Text>
        </Box>

        {transaction.updated_at && (
          <Box className="flex flex-row justify-between">
            <Text className="text-heading-06-sm-semibold">Updated at</Text>
            <Text className="text-p-01-medium">
              {formatDate(transaction.updated_at, "en", "D MMM YYYY, HH:mm:ss")}
            </Text>
          </Box>
        )}
      </Box>
      <Box className="border border-neutral-80 p-4 rounded-xl space-y-4">
        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Merchant city</Text>
          <Text className="text-p-01-medium">{transaction.merchant_city}</Text>
        </Box>

        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Merchant logo</Text>
          <Avatar src={transaction?.merchant_logo} size={32} />
        </Box>

        {transaction.merchant_name && (
          <Box className="flex flex-row justify-between">
            <Text className="text-heading-06-sm-semibold">Merchant name</Text>
            <Text className="text-p-01-medium">
              {transaction.merchant_name}
            </Text>
          </Box>
        )}

        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Merchant website</Text>
          <Text className="text-p-01-medium">
            {transaction.merchant_website}
          </Text>
        </Box>

        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Partners fee</Text>
          <Text className="text-p-01-medium">
            {transaction.partner_fee_formatted}
          </Text>
        </Box>
      </Box>

      <Box className="border border-neutral-80 p-4 rounded-xl space-y-4">
        <CopyableInformationItem label="Card id" value={transaction.card.id} />

        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Card Brand</Text>
          <Text className="text-p-01-medium">{transaction.card.brand}</Text>
        </Box>
        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Last four digits</Text>
          <Text className="text-p-01-medium">
            {transaction.card.last_four_digits}
          </Text>
        </Box>

        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Card label</Text>
          <Text className="text-p-01-medium">{transaction.card.label}</Text>
        </Box>

        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Card status</Text>
          {transaction.card.is_active ? (
            <Tag
              className="text-p-01 normal-case bg-success-90 py-3"
              size="lg"
              leftSection={
                <TickCircleFill className="w-4 h-4 text-success-30" />
              }
            >
              <Text component="span" className="text-success-30">
                Active
              </Text>
            </Tag>
          ) : (
            <Tag
              className="text-p-01 normal-case bg-critical-50 py-3"
              size="lg"
              leftSection={<CloseCircleFill className="w-4 h-4 text-white" />}
            >
              <Text component="span" className="text-white">
                Inactive
              </Text>
            </Tag>
          )}
        </Box>
      </Box>
    </Box>
  );
}

function CopyableInformationItem({
  label,
  value,
}: {
  label: string;
  value: string;
}) {
  const { copy, copied } = useClipboard({ timeout: 500 });

  return (
    <Box className="flex flex-row justify-between">
      <Text className="text-heading-06-sm-semibold">{label}</Text>
      <Box className="flex">
        <Text className="text-p-01-medium text-ellipsis overflow-hidden whitespace-nowrap w-40 ">
          {value}
        </Text>
        <Tooltip label={<Text>{copied ? "copied" : "copy"}</Text>}>
          <Box onClick={() => copy(value)} className="cursor-pointer">
            {copied ? (
              <CopySuccessOutline className="w-4 h-4 text-primary-40 ml-2" />
            ) : (
              <CopyOutline className="w-4 h-4 text-primary-40 ml-2" />
            )}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
}
