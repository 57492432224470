import { showErrorNotification, showSuccessNotification } from "@/utils";
import { Box, Button, InputField, Surface, Text } from "@axazara/raiton-atoms";
import { RepeatCircleOutline } from "@axazara/raiton-icons";
import { closeModal, useForm } from "@axazara/raiton-molecules";
import { Rate, RatePayload } from "utilities";
import { useCorrectionRateCalculator, useUpdateRate } from "../services";

export function UpdateExchangeRate({
  rate,
  refetch,
}: {
  rate: Rate;
  refetch: any;
}) {
  const updateRateForm = useForm<Pick<RatePayload, "correction_rate">>({
    initialValues: {
      correction_rate: rate?.correction_rate,
    },
  });

  const updateRateMutation = useUpdateRate(rate.id);

  const correctionRate = useCorrectionRateCalculator(
    updateRateForm.values.correction_rate,
    rate.value,
  );

  async function handleSubmit(values: Pick<RatePayload, "correction_rate">) {
    try {
      if (values.correction_rate !== rate.correction_rate) {
        const response = await updateRateMutation.mutateAsync(values);
        if (refetch) refetch();

        showSuccessNotification((response as any)?.message);
        closeModal("");
      }
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  return (
    <Box className="space-y-3">
      <Box className="flex justify-center gap-3 pt-12">
        <Text className="text-primary-50 text-heading-06-bold">
          {rate.base_currency.name}
        </Text>
        <Text className="text-primary-50 text-heading-06-bold" component="span">
          -
        </Text>
        <Text className="text-primary-50 text-heading-06-bold">
          {rate.destination_currency.name}
        </Text>
      </Box>
      <Box
        component="form"
        onSubmit={updateRateForm.onSubmit((values) => handleSubmit(values))}
      >
        <InputField
          required
          {...updateRateForm.getInputProps("correction_rate")}
          classNames={{
            label: "mb-3 text-p-01 text-neutral-40",
          }}
          placeholder="Enter correction rate"
          type="number"
          size="xs"
          label="Correction rate"
          icon={<RepeatCircleOutline className="w-5 h-5 text-neutral-40" />}
        />
        <Surface className="flex flex-row justify-between mt-4">
          <Text className="text-p-01-semibold text-neutral-40">
            New corrected value :
          </Text>
          <Text className="text-success-50 text-heading-06-sm-bold">
            {correctionRate}
          </Text>
        </Surface>

        <Box className="text-center pt-12 pb-5">
          <Button
            fullWidth
            type="submit"
            disabled={!updateRateForm.isDirty()}
            loading={updateRateMutation.isLoading}
          >
            Update rate
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
