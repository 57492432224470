import React from "react";
import {
  Box,
  Group,
  InputField,
  LoadingOverlay,
  Menu,
  UnstyledButton,
} from "@axazara/raiton-atoms";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  Arrow3Outline,
  ArrowDown1Outline,
  ArrowUpOutline,
  ArrowDownOutline,
} from "@axazara/raiton-icons";
import { Card } from "utilities";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";
import { EmptyState, PaginationControls } from "@/components";
import {
  CardTransactionsTable,
  SyncTransactionButton,
  useGetCardTransactions,
} from "@/features";
import { useTranslation } from "react-i18next";
import { useFilterDropdownStyles } from "@/utils";

function TransactionsTabPageRenderer({ Id }: { Id: string }) {
  const { t } = useTranslation();
  const typeData = [
    {
      label: `${t("all")}`,
      value: "all",
      icon: (
        <Box className="text-neutral-40 bg-neutral w-6 h-6 p-1 rounded-full flex items-center justify-center">
          <Arrow3Outline className="w-3 h-3" />
        </Box>
      ),
    },
    {
      label: `${t("credit")}`,
      value: "credit",
      icon: (
        <Box className="text-success-40 bg-success w-6 h-6 p-1 rounded-full flex items-center justify-center">
          <ArrowDown1Outline className="w-3 h-3" />
        </Box>
      ),
    },
    {
      label: `${t("debit")}`,
      value: "debit",
      icon: (
        <Box className="text-critical-40 bg-critical w-6 h-6 p-1 rounded-full flex items-center justify-center">
          <ArrowUpOutline className="w-3 h-3" />
        </Box>
      ),
    },
  ];

  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);
  const [type, setType] = React.useState<any>(typeData[0]);
  const [opened, setOpened] = React.useState(false);
  const { classes } = useFilterDropdownStyles({ opened, width: 150 });

  const { data: cardTransactions, isLoading } = useGetCardTransactions(Id, {
    page,
    search: debouncedSearch,
    type: type?.value,
  });

  const typeItems = typeData.map((item) => (
    <Menu.Item icon={item.icon} onClick={() => setType(item)} key={item.label}>
      {item.label}
    </Menu.Item>
  ));

  return (
    <Box>
      <Box className="pt-4">
        <Box className="flex flex-row w-full justify-between mb-5">
          <Box className="flex gap-2 w-full">
            <InputField
              classNames={{ input: "rounded-xl" }}
              radius={12}
              placeholder="Search transaction"
              size="xs"
              icon={<SearchNormal1Outline className="w-5 h-5" />}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full"
              value={searchQuery}
              rightSection={
                <Box>
                  {debouncedSearch ? (
                    <CloseCircleFill
                      className="w-4 h-4 text-neutral-40"
                      onClick={() => {
                        setSearchQuery("");
                      }}
                    />
                  ) : null}
                </Box>
              }
            />
            <Box className="flex flex-row gap-2">
              <Menu
                onOpen={() => setOpened(true)}
                onClose={() => setOpened(false)}
                radius="md"
                width="target"
                withinPortal
              >
                <Menu.Target>
                  <UnstyledButton className={classes.control}>
                    <Group spacing="xs">
                      {type?.icon}
                      <span className={classes.label}>{type?.label}</span>
                    </Group>
                    <ArrowDownOutline
                      className={classNames(
                        classes.icon,
                        "w-4 h-4 text-neutral-40",
                      )}
                    />
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>{typeItems}</Menu.Dropdown>
              </Menu>
              <SyncTransactionButton cardId={Id} />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className="relative">
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ color: "secondary" }}
            />
            {cardTransactions?.data && (
              <>
                <CardTransactionsTable transactions={cardTransactions.data} />
                <PaginationControls
                  page={page}
                  pagination={cardTransactions?.pagination}
                  onPageChange={setPage}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function TransactionsTab({ card }: { card: Card }) {
  const { data: cardTransactionsData, isLoading } = useGetCardTransactions(
    card?.id,
    { page: 1 },
  );

  if (isLoading) return <LoadingOverlay visible />;

  if (cardTransactionsData?.data && cardTransactionsData?.data?.length > 0)
    return <TransactionsTabPageRenderer Id={card?.id} />;

  return <EmptyState title="No transactions yet " />;
}
