import { Box, Text } from "@axazara/raiton-atoms";
import { CardOutline } from "@axazara/raiton-icons";

export function CardElements({ last_four_digits, label }: any) {
  return (
    <Box>
      <Box className="flex space-x-2 items-center">
        <Box className="rounded-full w-10 h-10 bg-neutral-90 flex justify-center items-center">
          <CardOutline className="w-4 text-neutral-40" />
        </Box>
        <Box className="flex flex-col">
          <Text className="text-caption-medium">**** {last_four_digits}</Text>
          <Text className="text-footer text-ellipsis overflow-hidden whitespace-nowrap w-16">
            {label}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
