import {
  Box,
  Text,
  Divider,
  Tabs,
  LoadingOverlay,
  Button,
} from "@axazara/raiton-atoms";
import {
  ProfileOutline,
  FrameFill,
  ArrowCircleLeftOutline,
} from "@axazara/raiton-icons";
import { Link, Navigate, useParams } from "react-router-dom";
import { openModal } from "@axazara/raiton-molecules";
import { EditStaff } from "./EditStaff";
import { useGetStaff } from "../services";
import { StaffActivities } from "./StaffActivities";
import { StaffInformation } from "./StaffInformation";
import { AccessControl } from "@/context";
import { StaffPermissions } from "utilities";
import { appPaths } from "@/configs";

function StaffDetailsPageRenderer({ id }: { id: string }) {
  const { data: staff } = useGetStaff(id);

  if (staff)
    return (
      <Box>
        <Box className="flex flex-col sticky top-0 bg-white z-20 rounded-xl mb-12">
          <Box className="flex md:p-6 justify-between items-center">
            <Box className="flex flow-row space-x-4">
              <Box
                state={staff}
                component={Link}
                to="/staffs"
                className="flex items-center"
              >
                <ArrowCircleLeftOutline className="w-6 h-6 text-neutral-30" />
              </Box>
              <Box className="flex items-center space-x-4">
                <Box className="rounded-full flex justify-center bg-secondary-50 w-14 h-14">
                  <Box className="flex items-center p-5">
                    <FrameFill className="w-6 h-6 text-neutral" />
                  </Box>
                </Box>
                <Box className="md:mt-1 flex-1">
                  <Text className="text-heading-04-sm-bold text-primary-50 mb-2">
                    {staff.full_name}
                  </Text>
                  <Text className="text-neutral-30 text-p-02-medium">
                    {staff.id}
                  </Text>
                </Box>
              </Box>
            </Box>
            <AccessControl permissions={StaffPermissions.UPDATE_STAFFS}>
              <Button
                size="xs"
                onClick={() =>
                  openModal({
                    title: (
                      <Text className="text-heading-06-semibold text-neutral-30">
                        Edit Staff member{" "}
                      </Text>
                    ),
                    children: <EditStaff staff={staff} />,
                  })
                }
              >
                Edit staff
              </Button>
            </AccessControl>
          </Box>
          <Divider className="border-neutral-90" />
        </Box>

        <Box className="pb-28">
          <Tabs
            styles={(theme) => ({
              tabsList: {
                borderBottomWidth: 1,
                borderColor: theme.colors.neutral[8],
                flexWrap: "nowrap",
                "@media (max-width: 670px)": {
                  overflow: "auto hidden",
                },
              },
              tab: {
                color: theme.colors.neutral[6],
              },
            })}
            defaultValue="staff_edit"
          >
            <Tabs.List className="w-max mx-auto flex justify-between mb-12">
              <Tabs.Tab
                icon={<ProfileOutline className="w-5 h-5 text-primary-4" />}
                value="staff_edit"
                className="text-p-01-semibold"
              >
                Information
              </Tabs.Tab>
              <Tabs.Tab
                icon={<ProfileOutline className="w-5 h-5 text-primary-4" />}
                value="staff_activity"
                className="text-p-01-semibold"
              >
                Activities
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="staff_edit">
              <StaffInformation staff={staff} />
            </Tabs.Panel>
            <Tabs.Panel value="staff_activity">
              <StaffActivities id={staff.id} />
            </Tabs.Panel>
          </Tabs>
        </Box>
      </Box>
    );

  return <LoadingOverlay visible />;
}

export function StaffDetailsPage() {
  const { id } = useParams();

  if (!id) return <Navigate to={appPaths.staffs.name} />;

  return <StaffDetailsPageRenderer id={id} />;
}
