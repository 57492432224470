import {
  Table,
  Box,
  Avatar,
  Tooltip,
  Tag,
  ActionIcon,
  Text,
} from "@axazara/raiton-atoms";
import {
  ArrowUpOutline,
  ArrowDown1Outline,
  SearchStatusOutline,
} from "@axazara/raiton-icons";
import { openModal } from "@axazara/raiton-molecules";
import { CardTransaction, formatDate } from "utilities";
import { CardTransactionsDetailsModal } from "./CardTransactionsDetailsModal";

export function CardTransactionsTableRow({
  transaction,
}: {
  transaction: CardTransaction;
}) {
  return (
    <Table.Row key={transaction.id} className="border-b border-neutral-90">
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {transaction.id}
      </Table.Cell>
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        <Box className="flex flex-row items-center space-x-1">
          <Avatar size={32} src={transaction?.merchant_logo} />
          <Box>
            <Text className="text-caption-medium">
              {transaction?.description}
            </Text>
            <Tooltip
              label={
                <Text className="text-footer">
                  {transaction?.merchant_name}
                </Text>
              }
            >
              <Text className="text-footer w-20 truncate">
                {transaction?.merchant_name}
              </Text>
            </Tooltip>
          </Box>
        </Box>
      </Table.Cell>
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {transaction.amount_formatted}
      </Table.Cell>

      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {transaction.type === "debit" && (
          <Tag
            size="lg"
            leftSection={<ArrowUpOutline className="w-4 h-4" />}
            className="text-critical-40 bg-critical"
          >
            Debit
          </Tag>
        )}
        {transaction.type === "credit" && (
          <Tag
            size="lg"
            leftSection={<ArrowDown1Outline className="w-4 h-4" />}
            className="text-success-40 bg-success"
          >
            Credit
          </Tag>
        )}
      </Table.Cell>
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {transaction.created_at &&
          formatDate(transaction.created_at, "en", "D MMM YYYY, HH:mm:ss")}
      </Table.Cell>

      <Table.Cell className="flex justify-end">
        <ActionIcon
          size="xl"
          radius={100}
          className="p-0.5"
          onClick={() =>
            openModal({
              centered: true,
              title: (
                <Text className="text-heading-06-bold text-primary-40">
                  Transaction card details
                </Text>
              ),
              children: (
                <CardTransactionsDetailsModal transaction={transaction} />
              ),
              size: "lg",
            })
          }
        >
          <SearchStatusOutline className="w-6 h-6 text-primary-50" />
        </ActionIcon>
      </Table.Cell>
    </Table.Row>
  );
}
