import { Table, ActionIcon, Text } from "@axazara/raiton-atoms";
import { SearchStatusOutline } from "@axazara/raiton-icons";
import { openModal } from "@axazara/raiton-molecules";
import { DeclinedTransaction, formatDate } from "utilities";
import { DeclinedTransactionDetailsModal } from "./DeclinedTransactionDetailsModal";

export function DeclinedTransactionsTableRow({
  transaction,
}: {
  transaction: DeclinedTransaction;
}) {
  return (
    <Table.Row key={transaction.id} className="border-b border-neutral-90">
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {transaction.id}
      </Table.Cell>
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {transaction.description}
      </Table.Cell>

      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {transaction.amount_formatted}
      </Table.Cell>

      <Table.Cell className="text-p-01 text-neutral-40">
        {transaction.created_at &&
          formatDate(transaction.created_at, "en", "D MMM YYYY, HH:mm:ss")}
      </Table.Cell>

      <Table.Cell>
        <ActionIcon
          size="xl"
          radius={100}
          className="p-0.5"
          onClick={() =>
            openModal({
              centered: true,
              title: (
                <Text className="text-heading-06-bold text-primary-40">
                  Declined transaction details
                </Text>
              ),
              children: (
                <DeclinedTransactionDetailsModal transaction={transaction} />
              ),
              size: "lg",
            })
          }
        >
          <SearchStatusOutline className="w-6 h-6 text-primary-50" />
        </ActionIcon>
      </Table.Cell>
    </Table.Row>
  );
}
