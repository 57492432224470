/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Axios from "axios";
import type { AxiosError } from "axios";
import { PersistentStorage, StorageKeys } from "@/utils";
import { appConfig, appPaths } from "@/configs";

export const axios = Axios.create({
  baseURL: appConfig.ZEYOW_API_BASE_URL as string,
  headers: {
    "Content-Type": "application/json",
  },
});

function getAccessToken() {
  const accessToken = PersistentStorage.getData(
    StorageKeys.ZEYOW_TOKEN_KEY,
    false,
  );
  return accessToken;
}

function isAuthPage(pathname: string) {
  return [appPaths.login.path].includes(pathname);
}

axios.interceptors.request.use(
  async (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers!.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

axios.interceptors.response.use(
  (res) => res.data,
  async (error: any) => {
    // access token expired
    if (
      error.response?.status === 401 &&
      !isAuthPage(window.location.pathname)
    ) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.pathname = appPaths.login.path;
      return Promise.reject(error.response);
    }

    return Promise.reject(error.response?.data);
  },
);
