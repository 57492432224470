import { Tag, Text } from "@axazara/raiton-atoms";
import { TickCircleOutline, CloseCircleOutline } from "@axazara/raiton-icons";

export function DiscountCodeStatusBadge({
  is_expired,
}: {
  is_expired?: boolean;
}) {
  if (is_expired) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-critical border border-critical-90 py-3 pl-1 pr-2"
        size="lg"
        leftSection={
          <CloseCircleOutline className="w-4 h-4 text-critical-40" />
        }
      >
        <Text
          component="span"
          className="text-critical-30 text-p-01-semibold ml-0.5"
        >
          Expired
        </Text>
      </Tag>
    );
  }

  return (
    <Tag
      className="text-p-01-semibold normal-case bg-success border border-success-90 py-3 pl-1 pr-2"
      size="lg"
      leftSection={<TickCircleOutline className="w-4 h-4 text-success-30" />}
    >
      <Text
        component="span"
        className="text-success-30 text-p-01-semibold ml-0.5"
      >
        Active
      </Text>
    </Tag>
  );
}

export function DiscountCodeIsEnableBadge({
  is_enabled,
}: {
  is_enabled?: boolean;
}) {
  if (is_enabled) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-primary border border-primary-60 py-3"
        size="lg"
      >
        <Text component="span" className="text-primary-30 text-p-01-semibold">
          True
        </Text>
      </Tag>
    );
  }

  return (
    <Tag
      className="text-p-01-semibold normal-case bg-primary border border-primary-60 py-3"
      size="lg"
    >
      <Text component="span" className="text-primary-30 text-p-01-semibold">
        False
      </Text>
    </Tag>
  );
}
