import {
  PurchasesStatusBadge,
  CopyableInformationItem,
  InformationRow,
  CardStatusBadge,
} from "@/components";
import { appPaths } from "@/configs";
import { useGetPurchase, useReloadPurchase } from "@/features";
import { useSetPageMeta } from "@/hooks";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import {
  ActionIcon,
  Box,
  Text,
  Divider,
  Surface,
  Tag,
  Avatar,
  Button,
  LoadingOverlay,
} from "@axazara/raiton-atoms";
import {
  CardAddOutline,
  ArrowCircleLeftOutline,
  Refresh2Outline,
  EyeOutline,
} from "@axazara/raiton-icons";
import { Navigate, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { formatDate } from "utilities";

function PurchasePageRenderer({ id }: { id: string }) {
  const { data: purchase, isError, isLoading, refetch } = useGetPurchase(id);

  const navigate = useNavigate();

  const useReloadMutation = useReloadPurchase(id);
  if (isLoading) return <LoadingOverlay visible />;

  async function handleReloadPurchase() {
    try {
      const response = await useReloadMutation.mutateAsync(undefined);
      refetch();
      showSuccessNotification((response as any).message);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }

  if (isError) return <Navigate to={appPaths.refills.path} />;

  return (
    <Box className="pt-4">
      <Box className="flex justify-between">
        <Box className="flex items-center gap-5 mb-6">
          <ActionIcon radius={100} onClick={() => navigate(-1)}>
            <ArrowCircleLeftOutline className="w-6 h-6" />
          </ActionIcon>
          <Box className="flex items-center space-x-2">
            <Box className="rounded-full w-10 h-10 bg-neutral-90 flex justify-center items-center">
              <CardAddOutline className="w-4 text-neutral-40" />
            </Box>
            <Box className="flex flex-col">
              <Text className="text-caption-medium">
                {/* {card?.label} | **** {card?.last_four_digits}{" "} */}
              </Text>
              <Text className="text-footer">@{purchase?.id}</Text>
            </Box>
          </Box>
        </Box>
        {purchase?.state === "failed" && (
          <Button
            onClick={() => handleReloadPurchase()}
            variant="outline"
            className="border border-neutral-50 text-neutral-50 mx-10"
            size="xs"
            rightIcon={
              useReloadMutation.isLoading ? (
                <Refresh2Outline className="w-4 h-4 animate-spin" />
              ) : (
                <Refresh2Outline className="w-4 h-4" />
              )
            }
          >
            Reload
          </Button>
        )}
      </Box>
      <Divider color="neutral.9" className="mb-12 border-neutral-90" />

      <Box className="flex justify-between gap-x-5 mb-16">
        <Box className="w-full">
          <Box className="border border-neutral-80 rounded-xl p-4 mb-4">
            {purchase?.id && (
              <CopyableInformationItem label="id" value={purchase?.id} />
            )}

            <InformationRow label="Price" value={purchase?.price_formatted} />
            <InformationRow label="Currency" value={purchase?.currency} />
            <InformationRow
              label="Correction rate"
              value={purchase?.correction_rate}
            />
            <InformationRow
              label="Exchange rate"
              value={purchase?.exchange_rate}
            />
            <InformationRow
              label="Price after discounts"
              value={purchase?.price_after_discount_formatted}
            />
            <InformationRow label="Provider" value={purchase?.provider} />
            <InformationRow
              label="Status"
              value={<PurchasesStatusBadge status={purchase?.state} />}
            />
            {purchase?.card_issued_at && (
              <InformationRow
                label="Card issued at"
                value={
                  purchase?.card_issued_at &&
                  formatDate(
                    purchase?.card_issued_at,
                    "en",
                    "D MMM YYYY, HH:mm:ss",
                  )
                }
              />
            )}
            <InformationRow
              label="Create at"
              value={
                purchase?.created_at &&
                formatDate(purchase?.created_at, "en", "D MMM YYYY, HH:mm:ss")
              }
            />

            {purchase?.updated_at && (
              <InformationRow
                label="Create at"
                value={
                  purchase?.updated_at &&
                  formatDate(purchase?.updated_at, "en", "D MMM YYYY, HH:mm:ss")
                }
              />
            )}
          </Box>
          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Text className="text-heading-06-semibold text-neutral-40 mb-4">
              Card informations
            </Text>

            {purchase?.card?.id && (
              <CopyableInformationItem label="Id" value={purchase?.card?.id} />
            )}
            <InformationRow
              label="Label"
              value={purchase?.card_details?.label}
            />
            <InformationRow
              label="Currency"
              value={purchase?.card_details?.currency}
            />
            <InformationRow
              label="Brand"
              value={purchase?.card_details?.brand}
            />
            <InformationRow label="Type" value={purchase?.card_details?.type} />
            <InformationRow
              label="Last four digits"
              value={purchase?.card?.last_four_digits.padStart(4, "*")}
            />
            <InformationRow
              label="Status"
              value={<CardStatusBadge status={purchase?.card?.is_active} />}
            />
            <Box className="flex justify-end">
              <Button
                component={Link}
                to={`${appPaths.cards.path}/${purchase?.card?.id}`}
                size="xs"
                variant="outline"
                rightIcon={<EyeOutline className="w-5 h-5" />}
              >
                View card
              </Button>
            </Box>
          </Surface>
        </Box>
        <Box className="w-full">
          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Text className="text-heading-06-semibold text-neutral-40 mb-4">
              Payments
            </Text>
            {purchase?.payment_transaction_id && (
              <CopyableInformationItem
                label="Id"
                value={purchase?.payment_transaction_id}
              />
            )}

            <InformationRow
              label="Amount"
              value={purchase?.payment_amount_formatted}
            />
            <InformationRow
              label="Currency"
              value={purchase?.payment_transaction_currency}
            />
            <InformationRow
              label="Gateway"
              value={purchase?.payment_transaction_gateway}
            />
            <InformationRow
              label="Status"
              value={
                <Tag
                  className="text-p-01-semibold normal-case bg-primary border border-primary-90 py-3 pl-1 pr-2"
                  size="lg"
                >
                  <Text
                    component="span"
                    className="text-primary-40 text-p-01-semibold ml-0.5"
                  >
                    {purchase?.payment_transaction_status}
                  </Text>
                </Tag>
              }
            />
          </Surface>
          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Text className="text-heading-06-semibold text-neutral-40 mb-4">
              User
            </Text>
            <Box className="flex justify-between gap-10">
              <Box className="flex items-center space-x-4">
                <Avatar src={purchase?.user?.profile_photo_url} size={48} />
                <Box>
                  <Text className="text-p-01-medium mb-2">
                    {purchase?.user?.first_name}
                    {purchase?.user?.last_name}
                  </Text>
                  <Text className="text-caption-medium text-neutral-40">
                    {purchase?.user?.email}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Button
                  component={Link}
                  to={`${appPaths.users.path}/${purchase?.user?.id}`}
                  size="xs"
                  variant="outline"
                  rightIcon={<EyeOutline className="w-5 h-5" />}
                >
                  View
                </Button>
              </Box>
            </Box>
          </Surface>

          {purchase?.failure_message && (
            <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
              <Text className="text-heading-06-semibold text-neutral-40 mb-4">
                Failed message
              </Text>

              <Text className="text-p-01-medium text-neutral-40">
                {purchase?.failure_message}
              </Text>
            </Surface>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export function PurchaseDetails() {
  useSetPageMeta({
    title: "Purchases",
    icon: CardAddOutline,
  });

  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.purchases.path} />;
  }

  return <PurchasePageRenderer id={id} />;
}
