import { Table, Box, ActionIcon, Text } from "@axazara/raiton-atoms";
import { Edit2Outline } from "@axazara/raiton-icons";
import { openModal } from "@axazara/raiton-molecules";
import { UpdateExchangeRate } from "./UpdateExchangeRate";
import { Rate } from "utilities";

export function ExchangeRatesTableRow({
  rate,
  refetch,
}: {
  rate: Rate;
  refetch: any;
}) {
  return (
    <Table.Row key={rate.id} className="border-b border-neutral-90">
      <Table.Cell>
        <Text className="text-p-01-medium text-neutral-40">
          {rate.base_currency.code}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">
          {rate.destination_currency.code}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01-medium text-neutral-40">
          {rate.correction_rate} %
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01-medium text-neutral-40">
          {rate.value_formatted}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01-medium text-neutral-40">
          {rate.corrected_value_formatted}
        </Text>
      </Table.Cell>

      <Table.Cell>
        <Box
          onClick={() =>
            openModal({
              children: <UpdateExchangeRate rate={rate} refetch={refetch} />,
              title: (
                <Text className="text-heading-06-bold">
                  Update exchange rate
                </Text>
              ),
              size: "lg",
              closeOnClickOutside: true,
              centered: true,
            })
          }
          className="flex flex-row justify-end space-x-2"
        >
          <ActionIcon color="primary" size="xl" radius={100} className="p-0.5">
            <Edit2Outline className="w-6 h-6 text-primary-50" />
          </ActionIcon>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}
