export default {
  "pages.dashboard.paymentStats": "Total payment transactions",
  "pages.dashboard.payoutStats": "Total payout transactions",
  "pages.dashboard.customerStats": "Total customers",
  "pages.dashboard.recentlyTransactions": "Recent transactions",
  "pages.dashboard.recentlyTransactions.description":
    "Here are your recent payment and payout transactions",
  "pages.dashboard.paymentsTransactions": "Payment transactions",
  "pages.dashboard.payoutsTransactions": "Payout transactions",

  "pages.transactionStatusInfo": "Transaction failed, here is the reason",
  "pages.transactionRecheck": "Recheck status",
  "pages.transactionRefund": "Refund",
  "pages.transactionRefundTooltip.availableSoon":
    "Transaction refund will be available soon.",

  "pages.login.title": "Sign in to your account",
  "pages.login.dontHaveAccount": "Don't have an account?",
  "pages.login.createAccount": "Sign up",
  "pages.login.button": "Sign in",

  "pages.apps.welcome": "Hi {{name}}, welcome back to Moneroo",
  "pages.apps.welcome.description":
    "Your dashboard is ready to use. Select your app to get started.",

  "pages.paymentTransactions.title": "Payment transactions",
  "pages.paymentTransactions.description": "Your app payment transactions",

  "pages.payoutTransactions.title": "Payout transactions",
  "pages.payoutTransactions.description": "Your app payout transactions",

  "pages.paymentTransactionDetails.title": "Payment transaction details",
  "pages.paymentTransactionDetails.description": "Transaction: #{{id}}",

  "pages.payoutTransactionDetails.title": "Payout transaction details",
  "pages.payoutTransactionDetails.description": "Transaction: #{{id}}",

  "pages.paymentMethodsList.availableList": "Available payment methods",
  "pages.payoutMethodsList.availableList": "Available payout methods",

  "pages.paymentsGateways.title": "Payment gateways",
  "pages.paymentsGateways.description": "Manage your payment gateways",

  "pages.payoutsGateways.title": "Payout gateways",
  "pages.payoutsGateways.description": "Manage your payout gateways",

  "pages.paymentGatewayAdd": "Add a payment gateway",
  "pages.payoutGatewayAdd": "Add a payout gateway",

  // Payment method
  "pages.paymentMethod.title": "Payment methods",
  "pages.paymentMethod.description": "Manage your payment methods",

  // Payout method
  "pages.payoutMethod.title": "Payout methods",
  "pages.payoutMethod.description": "Manage your payout methods",

  "pages.dashboard.title": "Dashboard",

  "pages.team.title": "Team",
  "pages.team.description": "Manage your team members",

  "pages.developers.title": "Developers",
  "pages.developers.description": "Manage your API keys and webhooks",

  "pages.customers.title": "Customers",
  "pages.customers.app": "App",
  "pages.customers.description": "Manage your customers",

  "pages.settings.title": "Settings",
  "pages.settings.description": "Manage your app settings",

  "pages.customerDetails.title": "Customer details",
  "pages.customerDetails.description": "Customer #{{ID}}",
};
