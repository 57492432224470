import { PersistentStorage } from "@/utils";
import { Box } from "@axazara/raiton-atoms";
import { ArrowLeft1Outline, ArrowRight1Outline } from "@axazara/raiton-icons";
import { useTranslation } from "react-i18next";

export function SidebarFlapper({
  opened,
  collapseSidebar,
  expandSidebar,
}: {
  opened: boolean;
  collapseSidebar: () => void;
  expandSidebar: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Box
      onClick={() => {
        if (opened) {
          collapseSidebar();
        } else {
          expandSidebar();
          PersistentStorage.remove("closed_sidebar");
        }
      }}
      className="border-t border-neutral-80 flex items-center w-full px-3 py-3 bg-white cursor-pointer"
    >
      <Box>
        {opened ? (
          <Box className="flex flex-row items-center space-x-2">
            <ArrowLeft1Outline className="w-5 h-5" />
            <span className="whitespace-nowrap text-p-01 text-neutral-40">
              {t("collapse_sidebar")}
            </span>
          </Box>
        ) : (
          <Box>
            <ArrowRight1Outline className="w-5 h-5" />
          </Box>
        )}
      </Box>
    </Box>
  );
}
