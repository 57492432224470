import { Box, Button } from "@axazara/raiton-atoms";
import { useSyncTransactions } from "../services";
import { Refresh2Outline } from "@axazara/raiton-icons";
import { classNames } from "@axazara/raiton-utils";
import { showSuccessNotification } from "@/utils";

export function SyncTransactionButton({ cardId }: { cardId: string }) {
  const syncTransactionMutation = useSyncTransactions(cardId);

  async function handleSync() {
    try {
      await syncTransactionMutation.mutateAsync(undefined, {
        onSuccess: (response) => {
          showSuccessNotification((response as any).message);
        },
      });
    } catch (error) {}
  }

  return (
    <Box className="flex justify-center">
      <Button
        onClick={() => handleSync()}
        leftIcon={
          <Refresh2Outline
            className={classNames(
              "w-6 h-6",
              syncTransactionMutation.isLoading && "animate-spin",
            )}
          />
        }
        size="xs"
      >
        Sync transactions
      </Button>
    </Box>
  );
}
