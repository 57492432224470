import { ApiEndpoints } from "@/configs";
import { useFetch, usePost } from "@/lib";
import { formatPath } from "@/utils";
import { buildFetchParams } from "@axazara/raiton-utils";
import {
  Card,
  PaginatedCardTransactions,
  PaginatedCards,
  PaginatedDeclinedTransactions,
  PaginatedRefills,
  TransactionType,
} from "utilities";

export const useGetCards = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedCards>(ApiEndpoints.cards, params);
};

export const useGetCardsDeclinedTransactions = (
  id: string,
  {
    page,
    search,
  }: {
    page?: number;
    search?: string;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedDeclinedTransactions>(
    `${ApiEndpoints.cards}/${id}/declines`,
    params,
  );
};

export const useGetCard = (id: string) =>
  useFetch<Card>(`${ApiEndpoints.cards}/${id}`);

export const useGetCardTransactions = (
  id: string,
  {
    page,
    search,
    type,
  }: {
    page?: number;
    search?: string;
    type?: TransactionType;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
    type,
  });
  return useFetch<PaginatedCardTransactions>(
    `${ApiEndpoints.cards}/${id}/transactions`,
    params,
  );
};

export const useSyncTransactions = (cardId: string) =>
  usePost(formatPath(ApiEndpoints.syncTransactions, { cardId }));

export const useGetCardRefills = (
  id: string,
  {
    page,
    search,
  }: {
    page?: number;
    search?: string;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedRefills>(
    `${ApiEndpoints.cards}/${id}/refills`,
    params,
  );
};
