import { useFilterDropdownStyles } from "@/utils";
import {
  Menu,
  Box,
  Group,
  UnstyledButton,
  InputField,
} from "@axazara/raiton-atoms";
import {
  ArrowDownOutline,
  SearchNormal1Outline,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import { classNames } from "@axazara/raiton-utils";
import React from "react";
import { useTranslation } from "react-i18next";

export interface StateData {
  label: string;
  value: string;
  color: string;
}

interface StatusFilterMenuProps {
  stateData: Array<StateData>;
  state: StateData | null;
  opened: boolean;
  setState: React.Dispatch<React.SetStateAction<StateData | null>>;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;

  // Search related fields
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  debouncedSearch: string;

  // URL parameter handling
  setUrlParams: any;
}

export const UserFilterMenu = ({
  stateData,
  state,
  setState,
  setOpened,
  opened,
  searchQuery,
  setSearchQuery,
  debouncedSearch,
  setUrlParams,
}: StatusFilterMenuProps) => {
  const { classes } = useFilterDropdownStyles({ opened: opened });

  const statusItems = stateData.map((item) => (
    <Menu.Item
      className={state?.value === item.value ? "bg-neutral-90" : ""}
      icon={<Box className={`w-3 h-3 rounded-full ${item.color}`} />}
      onClick={() => setState(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));
  const { t } = useTranslation();

  return (
    <Box className="flex w-full space-x-4 mb-6">
      <InputField
        placeholder={`${t("component.search")}`}
        size="xs"
        icon={<SearchNormal1Outline className="w-5 h-5" />}
        className="w-full"
        onChange={({ target }) => {
          setSearchQuery(target.value);
          setUrlParams({ search: target.value });
        }}
        value={searchQuery}
        rightSection={
          debouncedSearch ? (
            <CloseCircleFill
              className="w-4 h-4 text-neutral-60"
              onClick={() => {
                setSearchQuery("");
                setUrlParams({ search: "" });
              }}
            />
          ) : null
        }
        classNames={{
          input: "rounded-xl",
        }}
      />
      <Menu
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
        radius="md"
        width="100%"
        withinPortal
      >
        <Menu.Target>
          <UnstyledButton className={classes.control}>
            <Group spacing="xs">
              <Box className={`w-3 h-3 rounded-full ${state?.color}`} />
              <span className={classes.label}>{state?.label}</span>
            </Group>
            <ArrowDownOutline
              className={classNames(classes.icon, "w-4 h-4 text-neutral-40")}
            />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown className="!w-max">{statusItems}</Menu.Dropdown>
      </Menu>
    </Box>
  );
};
