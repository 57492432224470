import { appPaths } from "@/configs";
import { useSetPageMeta } from "@/hooks";
import {
  CardOutline,
  ArrowCircleLeftOutline,
  TaskSquareOutline,
  CardAddOutline,
  CardReceiveOutline,
} from "@axazara/raiton-icons";
import { Navigate, useNavigate, useParams } from "react-router";
import { useGetCard } from "../services";
import { ActionIcon, Box, Divider, Tabs, Text } from "@axazara/raiton-atoms";
import {
  DeclinedTransactionsTab,
  InformationTab,
  RefillsTab,
  TransactionsTab,
} from "@/features";

function CardDetailsPageRenderer({ id }: { id: string }) {
  const navigate = useNavigate();

  const { data: card, isError } = useGetCard(id);

  if (isError) return <Navigate to={appPaths.cards.path} />;

  return (
    <Box className="pt-4">
      <Box className="flex items-center gap-5 mb-6">
        <ActionIcon radius={100} onClick={() => navigate(-1)}>
          <ArrowCircleLeftOutline className="w-6 h-6" />
        </ActionIcon>
        <Box className="flex items-center space-x-2">
          <Box className="rounded-full w-10 h-10 bg-neutral-90 flex justify-center items-center">
            <CardOutline className="w-4 text-neutral-40" />
          </Box>
          <Box className="flex flex-col">
            <Text className="text-caption-medium">
              {card?.label} | **** {card?.last_four_digits}
            </Text>
            <Text className="text-footer">{card?.id}</Text>
          </Box>
        </Box>
      </Box>
      <Divider color="neutral.9" className="mb-12 border-neutral-90" />
      <Box>
        <Tabs
          styles={(theme) => ({
            tabsList: {
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              borderBottomWidth: 1,
              borderColor: theme.colors.neutral[8],
              flexWrap: "nowrap",
              "@media (max-width: 670px)": {
                overflow: "auto hidden",
              },
            },
            tab: {
              color: theme.colors.neutral[6],
            },
          })}
          defaultValue="information"
        >
          <Box className="flex justify-center">
            <Tabs.List className="mb-8">
              <Tabs.Tab
                className="md:text-p-02-semibold text-p-01-semibold w-full"
                value="information"
                icon={<TaskSquareOutline className="w-6 h-6" />}
              >
                Information
              </Tabs.Tab>
              <Tabs.Tab
                className="md:text-p-02-semibold text-p-01-semibold w-full"
                value="transaction"
                icon={<CardAddOutline className="w-6 h-6" />}
              >
                Transactions
              </Tabs.Tab>
              <Tabs.Tab
                className="md:text-p-02-semibold text-neutral-60 text-p-01-semibold w-full"
                value="refills"
                icon={<CardReceiveOutline className="w-6 h-6 " />}
              >
                Refills
              </Tabs.Tab>
              <Tabs.Tab
                className="md:text-p-02-semibold text-neutral-60 text-p-01-semibold w-full"
                value="declined"
                icon={<CardReceiveOutline className="w-6 h-6 " />}
              >
                Declined transactions
              </Tabs.Tab>
            </Tabs.List>
          </Box>
          <Tabs.Panel value="information">
            <InformationTab />
          </Tabs.Panel>
          <Tabs.Panel value="transaction">
            {card && <TransactionsTab card={card} />}
          </Tabs.Panel>
          <Tabs.Panel value="refills">
            <RefillsTab />
          </Tabs.Panel>
          <Tabs.Panel value="declined">
            {card && <DeclinedTransactionsTab card={card} />}
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Box>
  );
}

export function CardDetails() {
  useSetPageMeta({
    title: "Cards",
    icon: CardOutline,
  });

  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.cards.path} />;
  }

  return <CardDetailsPageRenderer id={id} />;
}
