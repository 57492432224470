import { ApiEndpoints } from "@/configs";
import { useFetch, usePost } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { CardPurchase, PaginatedPurchases } from "utilities";

export const useGetPurchases = ({
  page,
  search,
  initialData,
  status = "",
  brand = "",
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedPurchases;
  status?: string;
  brand?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
    status: status !== "all" ? status : "",
    brand: brand !== "all" ? brand : "",
  });
  return useFetch<PaginatedPurchases>(`${ApiEndpoints.purchases}`, params, {
    initialData,
  });
};

export const useGetPurchase = (id: string) =>
  useFetch<CardPurchase>(`${ApiEndpoints.purchases}/${id}`);

export const useReloadPurchase = (id: string) =>
  usePost<CardPurchase, any>(`${ApiEndpoints.purchases}/${id}/reload`);
