import { EmptyState, PaginationControls } from "@/components";
import { appPaths } from "@/configs";
import { Box, InputField, LoadingOverlay } from "@axazara/raiton-atoms";
import { SearchNormal1Outline, CloseCircleFill } from "@axazara/raiton-icons";
import { Navigate, useParams } from "react-router";
import { useGetCardRefills } from "../services";
import { useDebouncedValue } from "@axazara/raiton-utils";
import React from "react";
import { RefillsCardTable } from "@/features";

function RefillsTabPageRenderer({ id }: { id: string }) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);

  const { data: refills, isLoading } = useGetCardRefills(id, {
    page,
    search: debouncedSearch,
  });

  return (
    <Box>
      <Box className="pt-4">
        <Box className="flex flex-row w-full justify-between mb-5">
          <Box className="flex gap-2 w-full">
            <InputField
              classNames={{ input: "rounded-xl" }}
              radius={12}
              placeholder="Search refill"
              size="xs"
              icon={<SearchNormal1Outline className="w-5 h-5" />}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full"
              value={searchQuery}
              rightSection={
                <Box>
                  {debouncedSearch ? (
                    <CloseCircleFill
                      className="w-4 h-4 text-neutral-40"
                      onClick={() => {
                        setSearchQuery("");
                      }}
                    />
                  ) : null}
                </Box>
              }
            />
          </Box>
        </Box>
        <Box>
          <Box className="relative">
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ color: "secondary" }}
            />
            {refills?.data && (
              <>
                <RefillsCardTable refills={refills?.data} />
                <PaginationControls
                  page={page}
                  pagination={refills?.pagination}
                  onPageChange={setPage}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function RefillsTab() {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.cards.path} />;
  }

  const {
    data: refillsCardData,
    isLoading,
    isError,
  } = useGetCardRefills(id, {
    page: 1,
  });

  if (isError) return <Navigate to={appPaths.cards.path} />;

  if (isLoading) return <LoadingOverlay visible />;

  if (refillsCardData?.data && refillsCardData?.data.length > 0)
    return <RefillsTabPageRenderer id={id} />;

  return <EmptyState title="No refills yet" />;
}
