import { EmptyState, PaginationControls, RefillFilterMenu } from "@/components";
import { RefillsTable, useGetRefills, useRefills } from "@/features";
import { useSetPageMeta } from "@/hooks";
import { Box, LoadingOverlay } from "@axazara/raiton-atoms";
import { CardReceiveOutline } from "@axazara/raiton-icons";
import { PaginatedRefills } from "utilities";

function RefillsPageRenderer({
  initialData,
}: {
  initialData: PaginatedRefills;
}) {
  const {
    isLoading,
    searchQuery,
    setSearchQuery,
    debouncedSearch,
    refills,
    page,
    setPage,
    setState,
    setOpened,
    opened,
    stateData,
    state,
    setUrlParams,
  } = useRefills({
    initialData,
  });

  return (
    <Box>
      <Box className="pt-4">
        <RefillFilterMenu
          stateData={stateData}
          state={state}
          opened={opened}
          setState={setState}
          setOpened={setOpened}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          debouncedSearch={debouncedSearch}
          setUrlParams={setUrlParams}
        />
        <Box>
          <Box className="relative">
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ color: "secondary" }}
            />
            {refills?.data && (
              <>
                <RefillsTable refills={refills?.data} />
                <PaginationControls
                  page={page}
                  pagination={refills?.pagination}
                  onPageChange={setPage}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export function Refills() {
  useSetPageMeta({
    title: "Refills",
    icon: CardReceiveOutline,
  });

  const { data: refillsData, isLoading } = useGetRefills({
    page: 1,
  });

  if (isLoading) return <LoadingOverlay visible />;

  if (refillsData?.data && refillsData?.data.length > 0)
    return <RefillsPageRenderer initialData={refillsData} />;

  return <EmptyState title="No refills yet" />;
}
