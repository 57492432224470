import { EmptyState, PaginationControls } from "@/components";
import { useSetPageMeta } from "@/hooks";
import { Box, InputField, LoadingOverlay } from "@axazara/raiton-atoms";
import {
  RepeatCircleOutline,
  SearchNormal1Outline,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import { useGetRates } from "../services";
import { PaginatedRates } from "utilities";
import { useDebouncedValue } from "@axazara/raiton-utils";
import React from "react";
import { ExchangeRatesTable } from "../components";

function ExchangeRatesPageRenderer({
  ratesData,
}: {
  ratesData: PaginatedRates;
}) {
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const {
    data: rates,
    isLoading,
    refetch,
  } = useGetRates({
    initialData: ratesData,
    page,
    search: debouncedSearch,
  });

  return (
    <Box>
      <Box className="pt-4">
        <Box className="flex gap-2 mb-5">
          <InputField
            classNames={{ input: "rounded-xl" }}
            radius={12}
            placeholder="Search rate"
            size="xs"
            icon={<SearchNormal1Outline className="w-5 h-5" />}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full"
            value={searchQuery}
            rightSection={
              <Box>
                {debouncedSearch ? (
                  <CloseCircleFill
                    className="w-4 h-4 text-neutral-40"
                    onClick={() => {
                      setSearchQuery("");
                    }}
                  />
                ) : null}
              </Box>
            }
          />
        </Box>
        <Box>
          <Box className="relative">
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ color: "secondary" }}
            />
            {rates?.data && (
              <>
                <ExchangeRatesTable rates={rates?.data} refetch={refetch} />
                <PaginationControls
                  page={page}
                  pagination={rates?.pagination}
                  onPageChange={setPage}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export function ExchangeRates() {
  useSetPageMeta({
    title: "Exchange rates",
    icon: RepeatCircleOutline,
  });

  const { data: ratesData, isLoading } = useGetRates({
    page: 1,
  });

  if (isLoading) return <LoadingOverlay visible />;

  if (ratesData?.data && ratesData?.data.length > 0)
    return <ExchangeRatesPageRenderer ratesData={ratesData} />;

  return <EmptyState title="No exchange rate yet !" />;
}
