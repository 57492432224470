import { useSearchParams } from "react-router-dom";

export function useDynamicURLSearchParams<
  T extends Record<string, string | undefined>,
>(defaultParams?: T) {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParams = (): T => {
    let params = defaultParams || ({} as T);
    for (const key in params) {
      params[key as keyof T] = searchParams.get(key) as T[keyof T];
    }
    return params;
  };

  const setParams = (newParams: Partial<T>): void => {
    Object.entries(newParams).forEach(([key, value]) => {
      if (!value) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value as string);
      }
    });
    setSearchParams(searchParams, { replace: true });
  };

  return { params: getParams(), setParams };
}
