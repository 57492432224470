import { Box, InputField, Select, Button } from "@axazara/raiton-atoms";
import {
  ProfileOutline,
  ShieldSecurityFill,
  ProfileTickFill,
} from "@axazara/raiton-icons";
import { useForm } from "@axazara/raiton-molecules";
import { useUpdateStaffProfile } from "../services";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { roles } from "@/configs/roles";
import { Staff } from "@/models";

interface EditStaffForm {
  first_name?: string;
  last_name?: string;
  email?: string;
  role?: string;
  is_active: boolean;
}

export function EditStaff({ staff }: { staff: Staff }) {
  const staffStatus = [
    { label: "Inactive", value: false },
    { label: "Active", value: true },
  ];
  const editStaffForm = useForm<EditStaffForm>({
    initialValues: {
      first_name: staff.first_name,
      last_name: staff.full_name,
      email: staff.email,
      role: staff.role,
      is_active: staff.is_active,
    },
  });
  const useUpdateStaffProfileMutation = useUpdateStaffProfile(staff.id);

  async function handleSubmit(values: EditStaffForm) {
    try {
      if (
        values.first_name !== staff?.first_name ||
        values.last_name !== staff?.last_name ||
        values.email !== staff?.email ||
        values.role !== staff?.role ||
        values.is_active !== staff?.is_active
      ) {
        const response =
          await useUpdateStaffProfileMutation.mutateAsync(values);
        showSuccessNotification((response as any)?.message);
      }
    } catch (error) {
      showErrorNotification((error as any)?.response?.data?.message);
    }
  }

  return (
    <Box className="mx-auto max-w-xl">
      <Box className="mb-8">
        <Box
          onSubmit={editStaffForm.onSubmit((values) => handleSubmit(values))}
          component="form"
          className="space-y-6"
        >
          <InputField
            size="xs"
            label="First Name"
            icon={<ProfileOutline className="w-5 h-5 text-neutral-40" />}
            {...editStaffForm.getInputProps("first_name")}
            placeholder="Your first name"
          />
          <InputField
            size="xs"
            label="Last Name"
            icon={<ProfileOutline className="w-5 h-5 text-neutral-40" />}
            {...editStaffForm.getInputProps("last_name")}
            placeholder="Your Last name"
            defaultValue={staff.last_name}
          />
          <InputField
            size="xs"
            label="Email"
            icon={<ProfileOutline className="w-5 h-5 text-neutral-40" />}
            {...editStaffForm.getInputProps("email")}
            placeholder="Your Email"
            defaultValue={staff.email}
          />

          <Select
            data={roles}
            {...editStaffForm.getInputProps("role")}
            label="Role"
            type="text"
            size="xs"
            radius={12}
            icon={<ShieldSecurityFill className="h-5 w-5 text-neutral-40" />}
          />
          <Select
            data={staffStatus}
            {...editStaffForm.getInputProps("is_active")}
            label="Status"
            type="text"
            size="xs"
            radius={12}
            icon={<ShieldSecurityFill className="h-5 w-5 text-neutral-40" />}
          />
          <Box className="flex justify-center mt-4">
            <Button
              disabled={!editStaffForm.isDirty()}
              size="xs"
              loading={useUpdateStaffProfileMutation.isLoading}
              type="submit"
              className="text-caption-semibold"
              leftIcon={<ProfileTickFill className="h-5 w-5" />}
            >
              Update information
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
