import { closeModal, useForm } from "@axazara/raiton-molecules";
import {
  DateRange,
  DiscountCode,
  DiscountCodePayload,
  SimplifiedUser,
} from "utilities";
import { useGetAllUsers, useUpdateDiscountCode } from "../services";

import {
  showErrorNotification,
  showSuccessNotification,
  useFilterDropdownStyles,
} from "@/utils";
import {
  Avatar,
  Box,
  Button,
  DatePickerInput,
  Group,
  InputField,
  Menu,
  Select,
  Text,
  UnstyledButton,
} from "@axazara/raiton-atoms";
import {
  ArrowDownOutline,
  Calendar2Outline,
  SearchNormal1Outline,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import React from "react";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";

export function EditDiscountCode({
  discountCode,
}: {
  discountCode: DiscountCode;
}) {
  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>([
    null,
    null,
  ]);

  const [Opened, setOpened] = React.useState(false);

  const { classes } = useFilterDropdownStyles({
    opened: Opened,
    width: "100%",
  });

  const [searchQuery, setSearchQuery] = React.useState("");

  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const [userFilter, setUserFilter] = React.useState<SimplifiedUser | null>(
    discountCode.user,
  );

  const { data: users } = useGetAllUsers({ page: 1, search: debouncedSearch });

  const discountCodeType = [
    { label: "PERCENTAGE", value: "percentage" },
    { label: "FIXED", value: "fixed" },
  ];

  const editDiscountCodeForm = useForm<Partial<DiscountCodePayload>>({
    initialValues: {
      code: discountCode.code,
      type: discountCode.type,
      value: discountCode.value,
      use_limit: discountCode.use_limit,
      limit_per_user: discountCode.limit_per_user,
      valid_from: discountCode.valid_from,
      expires_at: discountCode.expires_at,
    },
  });

  const useUpdateDiscountCodeMutation = useUpdateDiscountCode({
    id: discountCode.id,
  });

  async function handleSubmit(values: Partial<DiscountCodePayload>) {
    try {
      if (
        values.code !== discountCode?.code ||
        values.type !== discountCode?.type ||
        values.value !== discountCode?.value ||
        values.use_limit !== discountCode?.use_limit ||
        values.limit_per_user !== discountCode?.limit_per_user ||
        values.expires_at !== discountCode?.expires_at ||
        values.valid_from !== discountCode?.valid_from
      ) {
        const response =
          await useUpdateDiscountCodeMutation.mutateAsync(values);
        showSuccessNotification((response as any)?.message);

        closeModal("");
      }
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  React.useEffect(() => {
    if (discountCode) {
      const dateRange: DateRange = [
        new Date(discountCode.valid_from),
        new Date(discountCode.expires_at),
      ];
      const updateDate = (_prevState: DateRange) => {
        return dateRange;
      };
      setSelectedDateRange(updateDate);
    }
  }, []);

  const userItems = users?.data?.map((item) => (
    <Menu.Item
      className={userFilter?.id === item?.id ? "bg-neutral-90" : ""}
      icon={<Avatar size={24} src={item?.profile_photo_url} />}
      onClick={() => {
        editDiscountCodeForm.setFieldValue("user_id", item?.id);
        setUserFilter(item);
      }}
      key={item?.id}
    >
      {item?.first_name} {item?.last_name}
    </Menu.Item>
  ));

  return (
    <Box>
      <Box
        component="form"
        onSubmit={editDiscountCodeForm.onSubmit(handleSubmit)}
      >
        <Box className="space-y-6 my-8">
          <InputField
            required
            size="xs"
            classNames={{
              input: "rounded-xl",
              label: "mb-3 text-p-01-semibold text-dark",
            }}
            label="Code"
            placeholder="Enter discount code "
            {...editDiscountCodeForm.getInputProps("code")}
          />

          <Select
            {...editDiscountCodeForm.getInputProps("type")}
            required
            label="Type"
            placeholder="Type"
            data={discountCodeType as any}
            optionResolver={(type: any) => ({
              label: type.label,
              value: type.value,
            })}
            classNames={{ label: "mb-3 text-p-01-semibold text-dark" }}
            type="text"
            size="xs"
            className="mb-5"
            radius={12}
            itemComponent={({ label, value, ...others }) => (
              <Box className="py-2" key={value} {...others}>
                <Box className="flex flex-row items-center ">
                  <Text className="text-p-01-medium">{label}</Text>
                </Box>
              </Box>
            )}
            styles={(theme) => ({
              input: {
                borderColor: theme.colors.neutral[6],
              },
            })}
          />

          <InputField
            required
            size="xs"
            classNames={{
              input: "rounded-xl",
              label: "mb-3 text-p-01-semibold text-dark",
            }}
            label="Value"
            placeholder="Enter discount code name"
            {...editDiscountCodeForm.getInputProps("value")}
          />

          <InputField
            size="xs"
            placeholder="Enter limit "
            classNames={{
              input: "rounded-xl",
              label: "mb-3 text-p-01-semibold text-dark",
            }}
            label="Limite d'utilisation "
            {...editDiscountCodeForm.getInputProps("use_limit")}
          />
          <InputField
            size="xs"
            placeholder="Enter limit per user"
            classNames={{
              input: "rounded-xl",
              label: "mb-3 text-p-01-semibold text-dark",
            }}
            label="Limite d'utilisation par user "
            {...editDiscountCodeForm.getInputProps("limit_per_user")}
          />

          <Box className="w-full">
            <Menu
              onOpen={() => setOpened(true)}
              onClose={() => setOpened(false)}
              radius="md"
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton className={`${classes.control} space-x-4`}>
                  {Opened ? (
                    <InputField
                      onClick={(e) => e.stopPropagation()}
                      styles={() => ({})}
                      classNames={{
                        input:
                          "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                      }}
                      value={searchQuery}
                      className="w-full"
                      iconWidth={12}
                      size="xs"
                      onChange={(e) => setSearchQuery(e.target.value)}
                      icon={<SearchNormal1Outline className="w-3 h-3" />}
                    />
                  ) : userFilter ? (
                    <>
                      <Group spacing="xs" className="w-full">
                        {userFilter?.profile_photo_url && (
                          <Avatar
                            size={24}
                            src={userFilter?.profile_photo_url}
                          />
                        )}
                        <span
                          className={`${classes.label} text-ellipsis overflow-hidden whitespace-nowrap w-16`}
                        >
                          {userFilter?.first_name} {userFilter?.last_name}
                        </span>
                      </Group>
                      {userFilter && (
                        <CloseCircleFill
                          className="w-4 h-4 text-neutral-50 mr-2"
                          onClick={() => {
                            setUserFilter(null);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Text
                      className="text-footer"
                      styles={() => ({ root: { fontSize: 12 } })}
                    >
                      Select user
                    </Text>
                  )}
                  <ArrowDownOutline
                    className={classNames(
                      classes.icon,
                      "w-4 h-4 text-neutral-40",
                    )}
                  />
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown style={{ maxHeight: "300px", overflow: "auto" }}>
                {userItems}
              </Menu.Dropdown>
            </Menu>
          </Box>

          <DatePickerInput
            // @ts-ignore
            placeholder="Choose validity and expiry date"
            size="xs"
            type="range"
            clearable
            icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
            value={selectedDateRange}
            numberOfColumns={2}
            styles={(theme) => ({
              root: {
                "> div": {
                  borderColor: theme.colors.neutral[8],
                },
              },
            })}
            onChange={(value) => {
              const [start, end] = value;
              setSelectedDateRange(value);
              if (start) {
                editDiscountCodeForm.setFieldValue(
                  "valid_from",
                  `${start.getFullYear()}-${
                    start.getMonth() + 1
                  }-${start.getDate()}`,
                );
              }
              if (end) {
                editDiscountCodeForm.setFieldValue(
                  "expires_at",
                  `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
                );
              }
            }}
            allowSingleDateSelectionInRange
            rightSection={
              selectedDateRange[1] ? null : (
                <ArrowDownOutline className="w-4 h-4 text-neutral-40" />
              )
            }
          />
        </Box>

        <Box className="flex justify-center">
          <Button
            type="submit"
            size="xs"
            disabled={!editDiscountCodeForm.isDirty()}
            loading={useUpdateDiscountCodeMutation.isLoading}
            className="text-caption-semibold"
          >
            Update discount code
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
