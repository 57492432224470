import { Box, Text, Avatar, Tabs, ActionIcon } from "@axazara/raiton-atoms";
import {
  Profile2userFill,
  ArrowCircleLeftOutline,
  TaskSquareOutline,
  CardOutline,
  CardReceiveOutline,
  CardAddOutline,
} from "@axazara/raiton-icons";
import {
  UserInformationTab,
  UserCardsTab,
  UserRefillsTab,
  UserPurchasesTab,
} from "./components";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useGetUser } from "@/features/Users/services";
import { useSetPageMeta } from "@/hooks";
import { appPaths } from "@/configs";

function UsersDetailsPageRenderer({ id }: { id: string }) {
  const navigate = useNavigate();

  const { data: user, isError } = useGetUser(id);

  if (isError) return <Navigate to={appPaths.users.path} />;

  return (
    <Box className="pt-4">
      <Box className="flex items-center gap-6">
        <ActionIcon radius={100} onClick={() => navigate(-1)}>
          <ArrowCircleLeftOutline className="w-6 h-6" />
        </ActionIcon>
        <Box className="md:flex-row flex flex-col items-center space-x-4">
          <Avatar src={user?.profile_photo_url} size={48} />
          <Box>
            <Text className="text-p-01-semibold text-neutral-20 mb-1.5">
              {user?.first_name} {user?.last_name}
            </Text>
            <Text className="text-caption-medium text-neutral-60">
              @{user?.id}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box>
        <Tabs
          styles={(theme) => ({
            tabsList: {
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              borderBottomWidth: 1,
              borderColor: theme.colors.neutral[8],
              flexWrap: "nowrap",
              "@media (max-width: 670px)": {
                overflow: "auto hidden",
              },
            },
            tab: {
              color: theme.colors.neutral[6],
            },
          })}
          defaultValue="information"
        >
          <Box className="flex justify-center">
            <Tabs.List className="mb-8">
              <Tabs.Tab
                className="md:text-p-02-semibold text-p-01-semibold w-full"
                value="information"
                icon={<TaskSquareOutline className="w-6 h-6" />}
              >
                Information
              </Tabs.Tab>

              <Tabs.Tab
                className="md:text-p-02-semibold text-p-01-semibold w-full"
                value="cards"
                icon={<CardOutline className="w-6 h-6" />}
              >
                Cards
              </Tabs.Tab>
              <Tabs.Tab
                className="md:text-p-02-semibold text-p-01-semibold w-full"
                value="refills"
                icon={<CardReceiveOutline className="w-6 h-6" />}
              >
                Refills
              </Tabs.Tab>
              <Tabs.Tab
                className="md:text-p-02-semibold text-p-01-semibold w-full"
                value="purchases"
                icon={<CardAddOutline className="w-6 h-6" />}
              >
                Purchases
              </Tabs.Tab>
            </Tabs.List>
          </Box>
          <Tabs.Panel value="information">
            {user && <UserInformationTab user={user} />}
          </Tabs.Panel>
          <Tabs.Panel value="cards">
            <UserCardsTab />
          </Tabs.Panel>
          <Tabs.Panel value="refills">
            <UserRefillsTab />
          </Tabs.Panel>
          <Tabs.Panel value="purchases">
            <UserPurchasesTab />
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Box>
  );
}

export function UsersDetails() {
  useSetPageMeta({
    title: "Users",
    icon: Profile2userFill,
  });

  const { id } = useParams();
  if (!id) {
    return <Navigate to={appPaths.users.path} />;
  }

  return <UsersDetailsPageRenderer id={id} />;
}
