import { appPaths } from "@/configs";
import { useSetPageMeta } from "@/hooks";
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Divider,
  LoadingOverlay,
  Surface,
  Text,
  Tooltip,
} from "@axazara/raiton-atoms";
import {
  ArrowCircleLeftOutline,
  CopySuccessOutline,
  CopyOutline,
  RefreshOutline,
  EditOutline,
  EyeOutline,
} from "@axazara/raiton-icons";
import { useClipboard } from "@axazara/raiton-utils";
import { Link, Navigate, useParams } from "react-router-dom";
import { useGetDiscountCode } from "../services";
import { formatDate } from "utilities";
import {
  DiscountCodeIsEnableBadge,
  DiscountCodeStatusBadge,
  EditDiscountCode,
} from "../components";
import { openModal } from "@axazara/raiton-molecules";
import { CopyableInformationItem, InformationRow } from "@/components";

function DiscountCodeDetailsPageRenderer({ id }: { id: string }) {
  const { copy: copyCode, copied: codeCopied } = useClipboard({ timeout: 500 });

  const { data: discountCode, isError, isLoading } = useGetDiscountCode({ id });

  if (isLoading) return <LoadingOverlay visible />;

  if (isError) return <Navigate to={appPaths.refills.path} />;

  return (
    <Box className="pt-4">
      <Box className="flex items-center justify-between gap-6">
        <Box className="flex items-center gap-5 mb-6">
          <ActionIcon
            component={Link}
            radius={100}
            to={appPaths.discountCodes.path}
          >
            <ArrowCircleLeftOutline className="w-6 h-6" />
          </ActionIcon>
          <Box className="md:flex-row flex flex-col items-center space-x-4">
            <Box>
              <Box className="flex justify-between items-center">
                <Text className="text-heading-05-bold mb-1">
                  {discountCode?.code}
                </Text>
                <Tooltip label={<Text>{codeCopied ? "copied" : "copy"}</Text>}>
                  <Box
                    onClick={() => copyCode(discountCode?.code)}
                    className="cursor-pointer"
                  >
                    {codeCopied ? (
                      <CopySuccessOutline className="w-4 h-4 text-primary-40 ml-2" />
                    ) : (
                      <CopyOutline className="w-4 h-4 text-primary-40 ml-2" />
                    )}
                  </Box>
                </Tooltip>
              </Box>
              <Text className="text-caption-medium text-neutral-40">
                @{discountCode?.id}
              </Text>
            </Box>
          </Box>
        </Box>

        <Button
          size="xs"
          leftIcon={<EditOutline className="w-4 h-4" />}
          onClick={() =>
            openModal({
              centered: true,
              children: discountCode && (
                <EditDiscountCode discountCode={discountCode} />
              ),
              size: "md",
              title: (
                <Text className="text-heading-05-bold text-neutral-30">
                  Edit discount code
                </Text>
              ),
            })
          }
        >
          Edit discount code
        </Button>
      </Box>
      <Divider color="neutral.9" className="mb-12 border-neutral-90" />

      <Box className="flex justify-between gap-x-5 mb-16">
        <Box className="w-full">
          <Box className="border border-neutral-80 rounded-xl p-4 mb-4">
            <CopyableInformationItem label="Id" value={discountCode?.id} />
            <CopyableInformationItem label="Code" value={discountCode?.code} />
            <InformationRow label="Type" value={discountCode?.type} />
            <InformationRow label="Value" value={discountCode?.value} />
            <InformationRow
              label="Status"
              value={
                <DiscountCodeStatusBadge
                  is_expired={discountCode?.is_expired}
                />
              }
            />
            <InformationRow
              label="Create at"
              value={
                discountCode?.created_at &&
                formatDate(
                  discountCode?.created_at,
                  "en",
                  "D MMM YYYY, HH:mm:ss",
                )
              }
            />
            <InformationRow
              label="Valid from"
              value={
                discountCode?.valid_from &&
                formatDate(
                  discountCode?.valid_from,
                  "en",
                  "D MMM YYYY, HH:mm:ss",
                )
              }
            />

            {discountCode?.expires_at && (
              <InformationRow
                label="Expire at"
                value={
                  discountCode?.expires_at &&
                  formatDate(
                    discountCode?.expires_at,
                    "en",
                    "D MMM YYYY, HH:mm:ss",
                  )
                }
              />
            )}
          </Box>
          {discountCode?.card_type && discountCode?.card_brand && (
            <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
              <InformationRow
                label="Card brand"
                value={discountCode?.card_brand}
              />
              <InformationRow
                label="Card type"
                value={discountCode?.card_type}
              />
            </Surface>
          )}
        </Box>
        <Box className="w-full">
          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <InformationRow
              label="Is enable"
              value={
                <DiscountCodeIsEnableBadge
                  is_enabled={discountCode?.is_enabled}
                />
              }
            />
            <InformationRow label="Total use" value={discountCode?.total_use} />
            {discountCode?.limit_per_user && (
              <InformationRow
                label="Limit per user"
                value={discountCode?.limit_per_user}
              />
            )}
            {discountCode?.use_limit && (
              <InformationRow
                label="Use Limit"
                value={discountCode?.use_limit}
              />
            )}
          </Surface>
          {discountCode?.user && (
            <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
              <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
                User
              </Text>
              <Box className="flex justify-between gap-10">
                <Box className="flex items-center space-x-4">
                  <Avatar
                    src={discountCode?.user?.profile_photo_url}
                    size={48}
                  />
                  <Box>
                    <Text className="text-p-01-medium mb-2">
                      {discountCode?.user?.first_name}
                      {discountCode?.user?.first_name}
                    </Text>
                    <Text className="text-caption-medium text-neutral-40">
                      {discountCode?.user?.email}
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Button
                    component={Link}
                    to={`${appPaths.users.path}/${discountCode?.user?.id}`}
                    size="xs"
                    variant="outline"
                    rightIcon={<EyeOutline className="w-5 h-5" />}
                  >
                    View
                  </Button>
                </Box>
              </Box>
            </Surface>
          )}

          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
              Created by
            </Text>
            <Box className="flex justify-between gap-10">
              <Box className="flex items-center space-x-4">
                <Avatar
                  src={discountCode?.added_by?.profile_photo_url}
                  size={48}
                />
                <Box>
                  <Text className="text-p-01-medium mb-2">
                    {discountCode?.added_by?.first_name}
                    {discountCode?.added_by?.first_name}
                  </Text>
                  <Text className="text-caption-medium text-neutral-40">
                    {discountCode?.added_by?.email}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Button
                  component={Link}
                  to={`${appPaths.staffs.path}/${discountCode?.added_by?.id}`}
                  size="xs"
                  variant="outline"
                  rightIcon={<EyeOutline className="w-5 h-5" />}
                >
                  View
                </Button>
              </Box>
            </Box>
          </Surface>
        </Box>
      </Box>
    </Box>
  );
}

export function DiscountCodeDetails() {
  useSetPageMeta({
    title: "Discount codes",
    icon: RefreshOutline,
  });
  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.discountCodes.path} />;
  }

  return <DiscountCodeDetailsPageRenderer id={id} />;
}
