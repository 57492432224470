import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "@/hooks";

export function PrivateOutlet() {
  const { token } = useAuth();
  const location = useLocation();
  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/auth/login" state={{ from: location }} />
  );
}
