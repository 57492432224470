import { Box, LoadingOverlay } from "@axazara/raiton-atoms";
import { Profile2userFill } from "@axazara/raiton-icons";
import { useGetUsers, useUsers } from "@/features";
import { PaginatedUsers } from "utilities";
import { EmptyState, PaginationControls, UserFilterMenu } from "@/components";
import { useSetPageMeta } from "@/hooks";
import { UsersTable } from "./components";

function UsersRendererPage({ initialData }: { initialData: PaginatedUsers }) {
  const {
    isLoading,
    searchQuery,
    setSearchQuery,
    debouncedSearch,
    users,
    page,
    setPage,
    setState,
    setOpened,
    opened,
    stateData,
    state,
    setUrlParams,
  } = useUsers({
    initialData,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="pt-4">
        <UserFilterMenu
          stateData={stateData}
          state={state}
          opened={opened}
          setState={setState}
          setOpened={setOpened}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          debouncedSearch={debouncedSearch}
          setUrlParams={setUrlParams}
        />
        <Box>
          {users?.data && (
            <>
              <UsersTable users={users.data} />
              <PaginationControls
                page={page}
                pagination={users?.pagination}
                onPageChange={setPage}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
export function Users() {
  useSetPageMeta({
    title: "Users",
    icon: Profile2userFill,
  });
  const { data: users, isLoading } = useGetUsers({ page: 1 });

  if (isLoading) return <LoadingOverlay visible />;

  if (users?.data && users.data.length > 0)
    return <UsersRendererPage initialData={users} />;

  return <EmptyState title="No user yet" />;
}
