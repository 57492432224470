import { Box, InputField, LoadingOverlay } from "@axazara/raiton-atoms";
import { SearchNormal1Outline, CloseCircleFill } from "@axazara/raiton-icons";
import { PaginatedPurchases } from "utilities";
import { useDebouncedValue } from "@axazara/raiton-utils";
import React from "react";
import { EmptyState, PaginationControls } from "@/components";
import { PurchasesTable, useGetUserPurchases } from "@/features";
import { appPaths } from "@/configs";
import { useParams, Navigate } from "react-router";

function PurchasesPageRenderer({
  purchasesData,
  id,
}: {
  purchasesData: PaginatedPurchases;
  id: string;
}) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);

  const { data: purchases, isLoading } = useGetUserPurchases(id, {
    page,
    search: debouncedSearch,
    initialData: purchasesData,
  });
  return (
    <Box>
      <Box className="pt-4">
        <Box className="flex flex-row w-full justify-between mb-5">
          <Box className="flex gap-2 w-full">
            <InputField
              classNames={{ input: "rounded-xl" }}
              radius={12}
              placeholder="Search purchase"
              size="xs"
              icon={<SearchNormal1Outline className="w-5 h-5" />}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full"
              value={searchQuery}
              rightSection={
                <Box>
                  {debouncedSearch ? (
                    <CloseCircleFill
                      className="w-4 h-4 text-neutral-40"
                      onClick={() => {
                        setSearchQuery("");
                      }}
                    />
                  ) : null}
                </Box>
              }
            />
          </Box>
        </Box>
        <Box>
          <Box className="relative">
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ color: "secondary" }}
            />
            {purchases?.data && (
              <>
                <PurchasesTable purchases={purchases?.data} />
                <PaginationControls
                  page={page}
                  pagination={purchases?.pagination}
                  onPageChange={setPage}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function UserPurchasesTab() {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.users.path} />;
  }

  const { data: purchasesData, isLoading } = useGetUserPurchases(id, {
    page: 1,
  });

  if (isLoading) return <LoadingOverlay visible />;

  if (purchasesData?.data && purchasesData?.data.length > 0)
    return <PurchasesPageRenderer purchasesData={purchasesData} id={id} />;

  return <EmptyState title="No purchase yet" />;
}
