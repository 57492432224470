import { Table, Box, Avatar, ActionIcon, Text } from "@axazara/raiton-atoms";
import { SearchStatusOutline } from "@axazara/raiton-icons";
import { Link } from "react-router-dom";
import { User, formatDate } from "utilities";
import { UserStateBadge } from "./UserStateBadge";

export function UserTableRow({ user }: { user: User }) {
  return (
    <Table.Row key={user.id} className="bg-white">
      <Table.Cell>
        <Text className="text-p-01-medium">{user.id}</Text>
      </Table.Cell>
      <Table.Cell>
        <Box className="md:flex-row flex flex-col items-center space-x-4">
          <Avatar src={user.profile_photo_url} size={48} />
          <Box>
            <Text className="text-p-01-semibold text-neutral-20">
              {user.first_name} {user.last_name}
            </Text>
            <Text className="text-caption-medium text-neutral-60">
              {user.email}
            </Text>
          </Box>
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">
          {formatDate(user.created_at, "en", "D MMM YYYY, HH:mm")}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <UserStateBadge state={user.state} />
      </Table.Cell>
      <Table.Cell>
        <Box
          component={Link}
          to={`/users/${user.id}`}
          className="cursor-pointer flex justify-end"
        >
          <ActionIcon color="primary" className="p-0.5" size="xl" radius={100}>
            <SearchStatusOutline className="w-6 h-6 text-primary-50" />
          </ActionIcon>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}
