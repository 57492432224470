import { appPaths } from "@/configs";
import {
  Avatar,
  Box,
  Button,
  LoadingOverlay,
  Surface,
  Text,
} from "@axazara/raiton-atoms";
import { SearchNormal1Outline } from "@axazara/raiton-icons";
import { formatDate } from "utilities";
import { useGetCard } from "../services";
import { Navigate, useParams } from "react-router";
import {
  CardStatusBadge,
  CopyableInformationItem,
  InformationRow,
} from "@/components";

function InformationCardPageRenderer({ id }: { id: string }) {
  const { data: card, isError, isLoading } = useGetCard(id);

  if (isLoading) return <LoadingOverlay visible />;

  if (isError) return <Navigate to={appPaths.cards.path} />;

  return (
    <Box className="flex justify-between gap-x-5 mb-16">
      <Box className="w-full">
        <Box className="border border-neutral-80 rounded-xl p-4 mb-4">
          {card?.id && <CopyableInformationItem label="Id" value={card?.id} />}

          <InformationRow label="Label" value={card?.label} />

          <InformationRow label=" Type" value={card?.type} />

          <InformationRow label="Brand" value={card?.brand} />

          <InformationRow
            label="Last four digits"
            value={card?.last_four_digits}
          />

          <InformationRow label="Balance" value={card?.balance_formatted} />

          <InformationRow
            label="Status"
            value={<CardStatusBadge status={card?.is_active} />}
          />

          <InformationRow
            label="  Create at"
            value={
              card?.created_at &&
              formatDate(card?.created_at, "en", "D MMM YYYY, HH:mm")
            }
          />
        </Box>
        <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
          {card?.provider_card_id && (
            <CopyableInformationItem
              label="Provider card id"
              value={card?.provider_card_id}
            />
          )}

          <InformationRow label="Provider name" value={card?.provider} />
        </Surface>
      </Box>
      <Box className="w-full">
        <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
          <Text className="text-heading-06-semibold text-neutral-40 mb-4">
            User
          </Text>
          <Box className="flex justify-between gap-10">
            <Box className="flex items-center space-x-4">
              <Avatar src={card?.user?.profile_photo_url} size={48} />
              <Box>
                <Text className="text-p-01-medium mb-2">
                  {card?.user?.first_name}
                  {card?.user?.last_name}
                </Text>
                <Text className="text-caption-medium text-neutral-40">
                  {card?.user?.email}
                </Text>
              </Box>
            </Box>
            <Box>
              <Button
                component="a"
                href={`${appPaths.users.path}/${card?.user?.id}`}
                size="xs"
                variant="outline"
                rightIcon={<SearchNormal1Outline className="w-5 h-5" />}
              >
                View
              </Button>
            </Box>
          </Box>
        </Surface>
        <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
          <Text className="text-heading-06-semibold text-neutral-40 mb-4">
            Billing address
          </Text>
          <InformationRow label="Address" value={card?.billing?.address} />

          <InformationRow label="City" value={card?.billing?.city} />

          <InformationRow
            label="Country code"
            value={card?.billing?.country_code}
          />

          <InformationRow label="State" value={card?.billing?.state} />

          <InformationRow
            label="State code"
            value={card?.billing?.state_code}
          />

          <InformationRow label="Zip code" value={card?.billing?.zip_code} />
        </Surface>
      </Box>
    </Box>
  );
}

export function InformationTab() {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.cards.path} />;
  }

  return <InformationCardPageRenderer id={id} />;
}
