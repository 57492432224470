import { ApiEndpoints } from "@/configs";
import { useFetch, usePost, useUpdate } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import {
  DiscountCode,
  DiscountCodePayload,
  PaginatedDiscountCodes,
  PaginatedUsers,
} from "utilities";

export const useGetDiscountCodes = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedDiscountCodes>(
    `${ApiEndpoints.discountCodes}`,
    params,
  );
};

export const useGetDiscountCode = ({ id }: { id: string }) =>
  useFetch<DiscountCode>(`${ApiEndpoints.discountCodes}/${id}`);

export const useUpdateDiscountCodeEnable = ({ id }: { id: string }) =>
  useUpdate<Partial<DiscountCode>, DiscountCode>(
    `${ApiEndpoints.discountCodes}/${id}`,
  );

export const useUpdateDiscountCode = ({ id }: { id: string }) =>
  useUpdate<Partial<DiscountCodePayload>, DiscountCode>(
    `${ApiEndpoints.discountCodes}/${id}`,
  );

export const useAddDiscountCode = () =>
  usePost<DiscountCodePayload, DiscountCode>(ApiEndpoints.discountCodes);

export const useGetAllUsers = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedUsers>(`${ApiEndpoints.users}`, params);
};
