import { Surface, Box } from "@axazara/raiton-atoms";

import { Outlet } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import { PermissionsProvider } from "@/context";
import { useGetProfile } from "@/services";
import { ErrorFallbackUI, Loading } from "@/components";
import { useAppDetails } from "./useAppDetails";
import { ErrorBoundary } from "react-error-boundary";
import { MobileNotSupported } from "./MobileNotSupported";

export function RootLayout() {
  const { data: staff } = useGetProfile();

  const { isMobileView, height, isSidebarOpened, setIsSidebarOpened } =
    useAppDetails();

  function handleExpandSidebar(callback?: () => void) {
    setIsSidebarOpened(true);
    callback?.();
  }

  function handleCollapseSidebar(callback?: () => void) {
    setIsSidebarOpened(false);
    callback?.();
  }

  const headerHeight = 80;
  const itemGap = 12;
  const layoutStyle = isSidebarOpened ? "minmax(250px, 270px) 1fr" : "90px 1fr";
  const contentHeight = height - (headerHeight + itemGap * 3);

  if (isMobileView) {
    return <MobileNotSupported />;
  }

  if (staff) {
    return (
      <Box className="fixed w-full h-full">
        <PermissionsProvider permissions={staff.permissions}>
          <Box
            className={`grid h-full bg-neutral grid-cols-[minmax(250px,_270px)_1fr]`}
            style={{ gridTemplateColumns: layoutStyle }}
          >
            <Sidebar
              isSidebarOpened={isSidebarOpened}
              onClose={handleCollapseSidebar}
              open={handleExpandSidebar}
            />

            <Box
              className="grid px-4 pt-4 pb-4 bg-neutral h-full"
              style={{
                rowGap: itemGap,
                gridTemplateRows: `${headerHeight} 1fr`,
              }}
            >
              <Header />
              <Surface
                component="section"
                style={{ height: contentHeight }}
                className="overflow-y-auto relative rounded-2xl scrollbar-hide"
              >
                <ErrorBoundary FallbackComponent={ErrorFallbackUI}>
                  <Box className="px-4 pb-5 h-full">
                    <Outlet />
                  </Box>
                </ErrorBoundary>
              </Surface>
            </Box>
          </Box>
        </PermissionsProvider>
      </Box>
    );
  }

  return (
    <Box className="w-full h-screen flex items-center justify-center">
      <Loading className="w-40 h-40" />
    </Box>
  );
}
