import { Box, Table } from "@axazara/raiton-atoms";
import { t } from "i18next";
import { Users } from "utilities";
import { UserTableRow } from "./UserTableRow";

export function UsersTable({ users }: { users: Users }) {
  return (
    <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral font-poppins">
          <Table.HeadCell>ID</Table.HeadCell>
          <Table.HeadCell>{t("component.customer")}</Table.HeadCell>
          <Table.HeadCell>{t("component.createdAt")}</Table.HeadCell>
          <Table.HeadCell>State</Table.HeadCell>
          <Table.HeadCell className="flex justify-end">
            {t("component.view")}
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-neutral-80">
          {users.map((user) => (
            <UserTableRow user={user} />
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
}
