import { ApiEndpoints } from "@/configs";
import { useFetch } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import {
  PaginatedCards,
  PaginatedPurchases,
  PaginatedRefills,
  PaginatedUsers,
  User,
} from "utilities";

export interface UserWithMessage extends User {
  message: string;
}

export const useGetUser = (id: string) =>
  useFetch<User>(`${ApiEndpoints.users}/${id}`);
export const useGetUserInformation = (id: string) =>
  useFetch<User>(`${ApiEndpoints.users}/${id}`);

export const useGetUsers = ({
  page = 1,
  search,
  initialData,
  status = "",
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedUsers;
  status?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
    status: status !== "all" ? status : "",
  });
  return useFetch<PaginatedUsers>(ApiEndpoints.users, params, { initialData });
};

export const useGetUserCards = (
  id: string,
  {
    page,
    search,
    initialData,
  }: {
    page?: number;
    search?: string;
    initialData?: PaginatedCards;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedCards>(`${ApiEndpoints.users}/${id}/cards`, params, {
    initialData,
  });
};

export const useGetUserRefills = (
  id: string,
  {
    page,
    search,
    initialData,
  }: {
    page?: number;
    search?: string;
    initialData?: PaginatedRefills;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedRefills>(
    `${ApiEndpoints.users}/${id}/refills`,
    params,
    { initialData },
  );
};

export const useGetUserPurchases = (
  id: string,
  {
    page,
    search,
    initialData,
  }: {
    page?: number;
    search?: string;
    initialData?: PaginatedPurchases;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedPurchases>(
    `${ApiEndpoints.users}/${id}/purchases`,
    params,
    { initialData },
  );
};
