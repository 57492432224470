import { Box, Table } from "@axazara/raiton-atoms";
import { Refills } from "utilities";
import { RefillsCardTableRow } from "./RefillsCardTableRow";

export function RefillsCardTable({ refills }: { refills: Refills }) {
  return (
    <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral font-poppins">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Id
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Card
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Status
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Amount
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Create at
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
            View
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-neutral-80 font-poppins">
          {refills.map((refill) => {
            return <RefillsCardTableRow refill={refill} />;
          })}
        </Table.Body>
      </Table>
    </Box>
  );
}
