import { ActionIcon, Box, Text } from "@axazara/raiton-atoms";
import { CopySuccessOutline, CopyOutline } from "@axazara/raiton-icons";
import { useClipboard } from "@axazara/raiton-utils";
import { DeclinedTransaction, formatDate } from "utilities";

export function DeclinedTransactionDetailsModal({
  transaction,
}: {
  transaction: DeclinedTransaction;
}) {
  const { copy, copied } = useClipboard({ timeout: 500 });

  return (
    <Box className="space-y-4 mt-8 font-poppins text-neutral-40">
      <Box className="border border-neutral-80 p-4 rounded-xl space-y-4">
        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Amount</Text>
          <Text className="text-p-01">{transaction.amount_formatted}</Text>
        </Box>

        <Box className="flex flex-row justify-between">
          <Text className="text-heading-06-sm-semibold">Date</Text>
          <Text className="text-p-01">
            {formatDate(transaction.created_at, "en", "D MMM YYYY, HH:mm:ss")}
          </Text>
        </Box>
      </Box>

      <Box className="border border-neutral-80 p-4 rounded-xl space-y-4">
        <Text className="text-heading-06-sm-semibold">Reason</Text>
        <Text className="text-p-01">{transaction.reason}</Text>
      </Box>

      <Box className="border border-neutral-80 p-4 rounded-xl space-y-4">
        <Text className="text-heading-06-sm-semibold">Description</Text>
        <Text className="text-p-01">{transaction.description}</Text>
      </Box>

      <Box className="border border-neutral-80 p-4 rounded-xl">
        <Text className="text-heading-06-sm-semibold mb-4">Reference</Text>
        <Box className="flex flex-row justify-between items-center">
          <Text className="text-p-01">{transaction.id}</Text>
          <ActionIcon onClick={() => copy(transaction.id)}>
            {copied ? (
              <CopySuccessOutline className="w-4 h-4" />
            ) : (
              <CopyOutline className="w-4 h-4" />
            )}
          </ActionIcon>
        </Box>
      </Box>
    </Box>
  );
}
