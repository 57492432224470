import { Authorization } from "./auth";
import { BaseEntity } from "./base";
import { PaginatedData, PaginatedType } from "./pagination";

export interface Language {
  symbol: "en" | "fr";
  name: string;
  symbol_native: string;
  icon: string;
  icon_url: string;
  code: "en" | "fr";
  name_and_icon: string;
}

export interface Settings {
  phone_country_code: string;
  phone_number: string;
  default_currency: string;
}

export interface User extends BaseEntity {
  is_new_user: boolean;
  first_name: string;
  last_name: string;
  front_identity: string | null;
  state: UserAccountState;
  email: string;
  profile_photo_url: string;
  email_verified_at: string;
  phone: string;
  phone_verified_at: any;
  is_suspended: boolean;
  identity_is_verified: boolean;
  two_factor_is_enabled: boolean;
  onboarded_at: string;
  created_at: string;
  updated_at: string;
  settings: Settings;
  locale: "en" | "fr";
  is_active: boolean;
  deleted_at: string;
  last_login_at: string;
}

export interface UserSettings {
  id: string;
  user: User;
  phone: {
    country_code: string;
    number: string;
  };
  default_currency: string;
  referral_link: string | null;
}

export enum UserAccountState {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export type OnboardPayload = Settings;
export interface Account {
  user: User;
  authorization: Authorization;
}

export type SimplifiedUser = Pick<
  User,
  "id" | "email" | "first_name" | "last_name" | "profile_photo_url"
>;

export type Users = User[];

export type PaginatedUsers = PaginatedType<PaginatedData, "data", Users>;
