import { useDebouncedValue } from "@axazara/raiton-utils";
import React from "react";
import { PaginatedPurchases } from "utilities";
import { useDynamicURLSearchParams } from "@/hooks";
import { useGetPurchases } from "@/features";

export function usePurchases({
  initialData,
}: {
  initialData: PaginatedPurchases;
}) {
  const stateData = [
    { label: "All", value: "all", color: "bg-neutral-50" },
    { label: "Initiated", value: "initiated", color: "bg-primary-40" },
    {
      label: "Pending payment",
      value: "pending_payment",
      color: "bg-secondary-40",
    },
    {
      label: "Pending issuing",
      value: "pending_issuing",
      color: "bg-secondary-40",
    },
    { label: "Success", value: "success", color: "bg-success-30" },
    {
      label: "Success payment",
      value: "payment_success",
      color: "bg-success-30",
    },
    { label: "Failed", value: "failed", color: "bg-critical-30" },
    {
      label: "Payment failed",
      value: "payment_failed",
      color: "bg-critical-30",
    },
  ];

  const typeData = [
    { label: "All", value: "all" },
    { label: "Visa card", value: "Visa" },
    { label: "Mastercard", value: "Mastercard" },
  ];

  const { params: urlParams, setParams: setUrlParams } =
    useDynamicURLSearchParams<{
      search: string;
      page: string;
      status: string;
      brand: string;
    }>({ search: "", page: "1", status: "all", brand: "all" });

  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const [opened, setOpened] = React.useState(false);
  const [openedType, setOpenedType] = React.useState(false);

  const [state, setState] = React.useState<any | null>(
    stateData.find((state) => state.value === urlParams.status) || stateData[0],
  );

  const [type, setType] = React.useState<any | null>(
    typeData.find((type) => type.value === urlParams.brand) || typeData[0],
  );

  const { data: purchases, isLoading } = useGetPurchases({
    page,
    search: debouncedSearch,
    initialData,
    status: state?.value || "all",
    brand: type?.value || "all",
  });

  return {
    purchases,
    isLoading,

    page,
    setPage,
    searchQuery,
    setSearchQuery,
    debouncedSearch,
    setState,

    opened,
    setUrlParams,
    setOpened,
    stateData,
    state,

    openedType,
    setOpenedType,
    setType,
    type,
    typeData,
  };
}
