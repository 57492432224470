import { CardElements, RefillStatusBadge } from "@/components";
import { appPaths } from "@/configs";
import { Table, ActionIcon } from "@axazara/raiton-atoms";
import { SearchStatusOutline } from "@axazara/raiton-icons";
import { Link } from "react-router-dom";
import { Refill, formatDate } from "utilities";

export function RefillsCardTableRow({ refill }: { refill: Refill }) {
  return (
    <Table.Row key={refill.id} className="border-b border-neutral-90">
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {refill.id}
      </Table.Cell>
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        <CardElements
          last_four_digits={refill?.card?.last_four_digits}
          label={refill?.card.label}
        />
      </Table.Cell>
      <Table.Cell className="text-p-01 text-neutral-40">
        <RefillStatusBadge status={refill?.state} />
      </Table.Cell>
      <Table.Cell className="text-p-01 text-neutral-40">
        {refill?.amount_formatted}
      </Table.Cell>
      <Table.Cell className="text-p-01 text-neutral-40">
        {refill.created_at &&
          formatDate(refill.created_at, "en", "D MMM YYYY, HH:mm")}
      </Table.Cell>

      <Table.Cell className="flex justify-end">
        <ActionIcon
          component={Link}
          to={`/${appPaths.refills.name}/${refill.id}`}
          size="xl"
          radius={100}
          className="p-0.5"
        >
          <SearchStatusOutline className="w-6 h-6 text-primary-50" />
        </ActionIcon>
      </Table.Cell>
    </Table.Row>
  );
}
