import { PurchasesStatusBadge } from "@/components";
import { appPaths } from "@/configs";
import { Table, ActionIcon, Avatar, Box, Text } from "@axazara/raiton-atoms";
import { SearchStatusOutline } from "@axazara/raiton-icons";
import { Link } from "react-router-dom";
import { CardPurchase } from "utilities";

export function PurchasesTableRow({ purchase }: { purchase: CardPurchase }) {
  return (
    <Table.Row key={purchase.id} className="border-b border-neutral-90">
      <Table.Cell className="text-p-01 text-neutral-40">
        {purchase.id}
      </Table.Cell>
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        <Box className="md:flex-row flex flex-col items-center space-x-4">
          <Avatar src={purchase?.user?.profile_photo_url} size={48} />
          <Box>
            <Text className="text-p-01-semibold text-neutral-20">
              {purchase?.user.first_name} {purchase?.user?.last_name}
            </Text>
            <Text className="text-caption-medium text-neutral-60">
              {purchase?.user?.email}
            </Text>
          </Box>
        </Box>
      </Table.Cell>
      <Table.Cell className="text-p-01 text-neutral-40">
        <PurchasesStatusBadge status={purchase?.state} />
      </Table.Cell>
      <Table.Cell className="text-p-01 text-neutral-40">
        {purchase?.price_formatted}
      </Table.Cell>
      <Table.Cell className="text-p-01 text-neutral-40">
        {purchase?.card_details?.type} | {purchase?.card_details?.brand}
      </Table.Cell>

      <Table.Cell className="flex justify-end">
        <ActionIcon
          component={Link}
          to={`/${appPaths.purchases.name}/${purchase.id}`}
          size="xl"
          radius={100}
          className="p-0.5"
        >
          <SearchStatusOutline className="w-6 h-6 text-primary-50" />
        </ActionIcon>
      </Table.Cell>
    </Table.Row>
  );
}
