import { appPaths } from "@/configs";
import { useGetInvitation, useAcceptInvitation } from "@/features/Auth/service";
import { showSuccessNotification, showErrorNotification } from "@/utils";
import { Box, LoadingOverlay } from "@axazara/raiton-atoms";
import { useClipboard } from "@axazara/raiton-utils";
import { Navigate, useNavigate } from "react-router-dom";
import { InvitationDetails } from "./InvitationDetails";
import { InvitationForm } from "./InvitationForm";

const handleInvitationAcceptance = async (
  mutateAsync: any,
  navigate: any,
  values: { otp_code: string },
) => {
  try {
    const response = await mutateAsync(values);
    showSuccessNotification(response.message);
    navigate(appPaths.login.path);
  } catch (error) {
    showErrorNotification((error as any)?.message);
  }
};

export function InvitationView({ token }: { token: string }) {
  const { data: invitation, isLoading, isError } = useGetInvitation(token);
  const { copied, copy } = useClipboard();
  const navigate = useNavigate();
  const acceptInvitationMutation = useAcceptInvitation(token);

  if (invitation) {
    return (
      <Box className="bg-neutral-90 py-16">
        <Box className="flex justify-center mb-10">
          <img
            src="https://cdn.axazara.com/brand/zeyow/logo.svg"
            width={150}
            height={32}
            alt="Zeyow"
          />
        </Box>
        <Box className="max-w-2xl mx-auto py-12 rounded-2xl bg-neutral p-16">
          <InvitationDetails
            invitation={invitation}
            copy={copy}
            copied={copied}
          />
          <InvitationForm
            acceptInvitationMutation={acceptInvitationMutation}
            onSubmit={(values: { otp_code: string }) =>
              handleInvitationAcceptance(
                acceptInvitationMutation.mutateAsync,
                navigate,
                values,
              )
            }
          />
        </Box>
      </Box>
    );
  }

  if (isError) return <Navigate to={appPaths.login.path} />;

  return <LoadingOverlay visible={isLoading} />;
}
