import { getEnvConfigFactory } from "@axazara/raiton-utils";

const currentEnv: string = process.env.VITE_AXAZARA_ENV || "dev";

const getEnvConfig = getEnvConfigFactory(currentEnv);

export const appConfig = {
  APP_ENV: getEnvConfig({
    dev: "dev",
    beta: "beta",
    alpha: "alpha",
    prod: "prod",
  }),

  AXAZARA_ACCOUNT_STAFF_DOMAIN: getEnvConfig({
    preset: "https://localhost:3002",
    beta: "https://account-staff.axaship.com",
    prod: "https://account.axazara.xn--6frz82g",
  }),

  ZEYOW_API_BASE_URL: getEnvConfig({
    dev: "https://zeyow-api.axaship.com",
    beta: "https://zeyow-api.axaship.com",
    prod: process.env.VITE_ZEYOW_API_BASE_URL,
  }),
};
