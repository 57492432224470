import { Box, Tag, Text } from "@axazara/raiton-atoms";
import {
  TickCircleOutline,
  ClockOutline,
  CloseCircleOutline,
  CdOutline,
} from "@axazara/raiton-icons";

export function PurchasesStatusBadge({ status }: { status?: string }) {
  if (status === "initiated") {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-primary border border-primary-90 py-3 pl-1 pr-2"
        size="lg"
        leftSection={<CdOutline className="w-4 h-4 text-primary-40" />}
      >
        <Text
          component="span"
          className="text-primary-40 text-p-01-semibold ml-0.5"
        >
          Initiated
        </Text>
      </Tag>
    );
  }

  if (status === "pending_payment") {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-secondary border border-secondary-90 py-3 pl-1 pr-2"
        size="lg"
        leftSection={<ClockOutline className="w-4 h-4 text-secondary-40" />}
      >
        <Text
          component="span"
          className="text-secondary-40 text-p-01-semibold ml-0.5"
        >
          Pending payment
        </Text>
      </Tag>
    );
  }

  if (status === "pending_issuing") {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-secondary border border-secondary-90 py-3 pl-1 pr-2"
        size="lg"
        leftSection={<ClockOutline className="w-4 h-4 text-secondary-40" />}
      >
        <Text
          component="span"
          className="text-secondary-40 text-p-01-semibold ml-0.5"
        >
          Pending issuing
        </Text>
      </Tag>
    );
  }

  if (status === "success") {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-success border border-success-90 py-3 pl-1 pr-2"
        size="lg"
        leftSection={<TickCircleOutline className="w-4 h-4 text-success-30" />}
      >
        <Text
          component="span"
          className="text-success-30 text-p-01-semibold ml-0.5"
        >
          Success
        </Text>
      </Tag>
    );
  }

  if (status === "payment_success") {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-success border border-success-90 py-3 pl-1 pr-2"
        size="lg"
        leftSection={<TickCircleOutline className="w-4 h-4 text-success-30" />}
      >
        <Text
          component="span"
          className="text-success-30 text-p-01-semibold ml-0.5"
        >
          Payment success
        </Text>
      </Tag>
    );
  }

  if (status === "failed") {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-critical border border-critical-90 py-3 pl-1 pr-2"
        size="lg"
        leftSection={
          <CloseCircleOutline className="w-4 h-4 text-critical-40" />
        }
      >
        <Text
          component="span"
          className="text-critical-30 text-p-01-semibold ml-0.5"
        >
          Failed
        </Text>
      </Tag>
    );
  }

  if (status === "payment_failed") {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-critical border border-critical-90 py-3 pl-1 pr-2"
        size="lg"
        leftSection={
          <CloseCircleOutline className="w-4 h-4 text-critical-40" />
        }
      >
        <Text
          component="span"
          className="text-critical-30 text-p-01-semibold ml-0.5"
        >
          Payment failed
        </Text>
      </Tag>
    );
  }

  return <Box />;
}
