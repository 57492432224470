import { ApiEndpoints } from "@/configs";
import { useFetch, usePost } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { PaginatedRefills, Refill } from "utilities";

export const useGetRefills = ({
  page,
  search,
  initialData,
  status = "",
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedRefills;
  status?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
    status: status !== "all" ? status : "",
  });
  return useFetch<PaginatedRefills>(`${ApiEndpoints.refills}`, params, {
    initialData,
  });
};

export const useGetRefill = (id: string) =>
  useFetch<Refill>(`${ApiEndpoints.refills}/${id}`);

export const useReloadRefill = (id: string) =>
  usePost<Refill, any>(`${ApiEndpoints.refills}/${id}/reload`);
