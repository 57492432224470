import { Box, Text, Tag, Button } from "@axazara/raiton-atoms";
import { CloseCircleOutline } from "@axazara/raiton-icons";
import { User, formatDate } from "utilities";
import { useTranslation } from "react-i18next";
import { UserStateBadge } from "./UserStateBadge";
import { Link } from "react-router-dom";
import { appConfig } from "@/configs";

export function UserInformationTab({ user }: { user: User }) {
  const { t } = useTranslation();

  return (
    <Box className="max-w-xs mx-auto">
      <Box className="space-y-4">
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">ID</Text>
          <Text className="text-neutral-30 text-p-01">{user?.id}</Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.firstName")}
          </Text>
          <Text className="text-neutral-30 text-p-01">{user?.first_name}</Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.lastName")}
          </Text>
          <Text className="text-neutral-30 text-p-01">{user?.last_name}</Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.email")}
          </Text>
          <Text className="text-neutral-30 text-p-01">{user?.email}</Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.signAt")}
          </Text>
          <Text className="text-neutral-30 text-p-01">
            {user?.created_at && formatDate(user?.created_at, "en")}
          </Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.lastLogin")}
          </Text>
          <Text className="text-neutral-30 text-p-01">
            {user?.last_login_at && formatDate(user?.last_login_at, "en")}
          </Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">State</Text>
          <UserStateBadge state={user.state} />
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            Identity status
          </Text>
          {user?.identity_is_verified ? (
            <Tag
              className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-success-30 text-p-01-semibold"
              >
                {t("component.verified")}
              </Text>
            </Tag>
          ) : (
            <Tag
              className="text-p-01-semibold normal-case bg-primary border border-primary-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-primary-30 text-p-01-semibold"
              >
                {t("component.unverified")}
              </Text>
            </Tag>
          )}
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.2faStatus")}
          </Text>
          {user?.two_factor_is_enabled ? (
            <Tag
              className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-success-30 text-p-01-semibold"
              >
                {t("component.enable")}
              </Text>
            </Tag>
          ) : (
            <Tag
              className="text-p-01-semibold normal-case bg-critical border border-critical-90 py-3 pl-1 pr-2"
              size="lg"
              leftSection={
                <CloseCircleOutline className="w-4 h-4 text-critical-40" />
              }
            >
              <Text
                component="span"
                className="text-critical-30 text-p-01-semibold ml-0.5"
              >
                {t("component.disable")}
              </Text>
            </Tag>
          )}
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.country")}
          </Text>
          <Text className="text-neutral-30 text-p-01">
            {user?.settings.phone_country_code}
          </Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.currency")}
          </Text>
          <Text className="text-neutral-30 text-p-01">
            {user?.settings.default_currency}
          </Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.phone_number")}
          </Text>
          <Text className="text-neutral-30 text-p-01">
            {user?.settings.phone_number}
          </Text>
        </Box>
        <Box className="flex justify-center">
          <Button
            target="_blank"
            variant="outline"
            size="xs"
            component={Link}
            to={`${appConfig.AXAZARA_ACCOUNT_STAFF_DOMAIN}/users/${user.id}`}
          >
            View user
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
