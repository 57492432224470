export const appPaths = {
  root: "/",
  auth: "/auth",
  login: { path: "/auth/login", name: "login" },
  invitation: { path: "/auth/invitation/:token", name: "invitation" },

  users: { path: "/users", name: "users" },
  usersDetails: { path: "/users/:id", name: "users-details" },

  cards: { path: "/cards", name: "cards" },
  cardDetails: { path: "/cards/:id", name: "cards" },

  refills: { path: "/refills", name: "refills" },
  refillDetails: { path: "/refills/:id", name: "refills" },

  discountCodes: { path: "/discounts", name: "discounts" },
  discountCodeDetails: { path: "/discounts/:id", name: "discounts" },

  exchangeRate: { path: "/exchange-rate", name: "exchange-rate" },
  exchangeRateDetails: { path: "/exchange-rate/:id", name: "exchange-rate" },

  purchases: { path: "/purchases", name: "purchases" },
  purchaseDetails: { path: "/purchases/:id", name: "purchases" },

  transactions: { path: "/transactions", name: "transactions" },

  staffs: { path: "/staffs", name: "staffs" },
  viewStaff: { path: "/staffs/:id", name: "staff-view" },
};
