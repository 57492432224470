import { appPaths } from "@/configs";
import { useSetPageMeta } from "@/hooks";
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Divider,
  LoadingOverlay,
  Surface,
  Tag,
  Text,
} from "@axazara/raiton-atoms";
import {
  CardReceiveOutline,
  ArrowCircleLeftOutline,
  Refresh2Outline,
  EyeOutline,
} from "@axazara/raiton-icons";
import { Navigate, useNavigate, useParams } from "react-router";
import { useGetRefill, useReloadRefill } from "../services";
import {
  CardStatusBadge,
  CopyableInformationItem,
  InformationRow,
  RefillStatusBadge,
} from "@/components";
import { formatDate } from "utilities";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { Link } from "react-router-dom";

function RefillDetailsPageRenderer({ id }: { id: string }) {
  const navigate = useNavigate();

  const { data: refill, refetch, isError, isLoading } = useGetRefill(id);

  const useReloadMutation = useReloadRefill(id);

  if (isLoading) return <LoadingOverlay visible />;

  async function handleReloadRefill() {
    try {
      const response = await useReloadMutation.mutateAsync(undefined);
      refetch();
      showSuccessNotification((response as any).message);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }

  if (isError) return <Navigate to={appPaths.refills.path} />;

  return (
    <Box className="pt-4">
      <Box className="flex justify-between">
        <Box className="flex items-center gap-5 mb-6">
          <ActionIcon radius={100} onClick={() => navigate(-1)}>
            <ArrowCircleLeftOutline className="w-6 h-6" />
          </ActionIcon>
          <Box className="flex items-center space-x-2">
            <Box className="rounded-full w-10 h-10 bg-neutral-90 flex justify-center items-center">
              <CardReceiveOutline className="w-4 text-neutral-40" />
            </Box>
            <Box className="flex flex-col">
              <Text className="text-footer">@{refill?.id}</Text>
            </Box>
          </Box>
        </Box>
        {refill?.state === "failed" && (
          <Button
            onClick={() => handleReloadRefill()}
            variant="outline"
            className="border border-neutral-50 text-neutral-50 mx-10"
            size="xs"
            rightIcon={
              useReloadMutation.isLoading ? (
                <Refresh2Outline className="w-4 h-4 animate-spin" />
              ) : (
                <Refresh2Outline className="w-4 h-4" />
              )
            }
          >
            Reload
          </Button>
        )}
      </Box>
      <Divider color="neutral.9" className="mb-12 border-neutral-90" />

      <Box className="flex justify-between gap-x-5 mb-16">
        <Box className="w-full">
          <Box className="border border-neutral-80 rounded-xl p-4 mb-4">
            {refill?.id && (
              <CopyableInformationItem label="Id" value={refill?.id} />
            )}

            <InformationRow label="Amount" value={refill?.amount_formatted} />
            <InformationRow label="Currency" value={refill?.currency} />
            <InformationRow
              label="Correction rate"
              value={refill?.correction_rate}
            />
            <InformationRow
              label=" Exchange rate"
              value={refill?.exchange_rate}
            />
            <InformationRow
              label="Status"
              value={<RefillStatusBadge status={refill?.state} />}
            />
            <InformationRow
              label="Create at"
              value={
                refill?.created_at &&
                formatDate(refill.created_at, "en", "D MMM YYYY, HH:mm:ss")
              }
            />
            {refill?.failed_at && (
              <InformationRow
                label="Failed at"
                value={
                  refill?.failed_at &&
                  formatDate(refill.failed_at, "en", "D MMM YYYY, HH:mm:ss")
                }
              />
            )}
            {refill?.success_at && (
              <InformationRow
                label="  Success at"
                value={
                  refill?.success_at &&
                  formatDate(refill.success_at, "en", "D MMM YYYY, HH:mm:ss")
                }
              />
            )}
            <InformationRow
              label="Update at"
              value={
                refill?.updated_at &&
                formatDate(refill.updated_at, "en", "D MMM YYYY, HH:mm:ss")
              }
            />
          </Box>
          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Text className="text-heading-06-semibold text-neutral-40 mb-4">
              Card information
            </Text>
            {refill?.card?.id && (
              <CopyableInformationItem label="Id" value={refill?.card?.id} />
            )}

            {refill?.card?.provider_card_id && (
              <CopyableInformationItem
                label="Provider card id"
                value={refill?.card?.provider_card_id}
              />
            )}
            <InformationRow label="Label" value={refill?.card?.label} />
            <InformationRow label="Brand" value={refill?.card?.brand} />
            <InformationRow
              label="Last four digits"
              value={refill?.card?.last_four_digits.padStart(4, "*")}
            />
            <InformationRow
              label="Status"
              value={<CardStatusBadge status={refill?.card?.is_active} />}
            />
            <Box className="flex justify-end">
              <Button
                component={Link}
                to={`${appPaths.cards.path}/${refill?.card?.id}`}
                size="xs"
                variant="outline"
                rightIcon={<EyeOutline className="w-5 h-5" />}
              >
                View card
              </Button>
            </Box>
          </Surface>
        </Box>
        <Box className="w-full">
          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Text className="text-heading-06-semibold text-neutral-40 mb-4">
              Payments
            </Text>
            {refill?.payment_transaction_id && (
              <CopyableInformationItem
                label="Id"
                value={refill?.payment_transaction_id}
              />
            )}

            <InformationRow
              label="Amount"
              value={refill?.payment_amount_formatted}
            />
            <InformationRow
              label="Currency"
              value={refill?.payment_transaction_currency}
            />
            <InformationRow
              label="Gateway"
              value={refill?.payment_transaction_gateway}
            />
            <InformationRow
              label="Status"
              value={
                <Tag
                  className="text-p-01-semibold normal-case bg-primary border border-primary-90 py-3 pl-1 pr-2"
                  size="lg"
                >
                  <Text
                    component="span"
                    className="text-primary-40 text-p-01-semibold ml-0.5"
                  >
                    {refill?.payment_transaction_status}
                  </Text>
                </Tag>
              }
            />
          </Surface>
          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Text className="text-heading-06-semibold text-neutral-40 mb-4">
              User
            </Text>

            <Box className="flex justify-between gap-10">
              <Box className="flex items-center space-x-4">
                <Avatar src={refill?.user?.profile_photo_url} size={48} />
                <Box>
                  <Text className="text-p-01-medium mb-2">
                    {refill?.user?.first_name}
                    {refill?.user?.last_name}
                  </Text>
                  <Text className="text-caption-medium text-neutral-40">
                    {refill?.user?.email}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Button
                  component="a"
                  href={`${appPaths.users.path}/${refill?.user?.id}`}
                  size="xs"
                  variant="outline"
                  rightIcon={<EyeOutline className="w-5 h-5" />}
                >
                  View
                </Button>
              </Box>
            </Box>
          </Surface>
          {refill?.failure_message && (
            <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
              <Text className="text-heading-06-semibold text-neutral-40 mb-4">
                Failed message
              </Text>

              <Text className="text-p-01-medium text-neutral-40">
                {refill?.failure_message}
              </Text>
            </Surface>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export function RefillDetails() {
  useSetPageMeta({
    title: "Refills",
    icon: CardReceiveOutline,
  });

  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.refills.path} />;
  }

  return <RefillDetailsPageRenderer id={id} />;
}
