import { Box, Text } from "@axazara/raiton-atoms"; // Adjust import paths based on your actual package

export const MobileNotSupported = () => {
  return (
    <Box className="w-full h-screen flex flex-col items-center justify-center text-center">
      <Text className="text-display-02 mb-2">🤕</Text>
      <Text>
        Sorry, we don’t support devices at this resolution yet. This will come
        soon, promise.
      </Text>
    </Box>
  );
};
