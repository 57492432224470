import { ApiEndpoints } from "@/configs";
import { useFetch } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import {
  DateRange,
  PaginatedCardTransactions,
  TransactionType,
  formatDate,
} from "utilities";

export const useGetTransactions = ({
  page,
  search,
  type,
  initialData,
  dateRange = [null, null],
}: {
  page?: number;
  search?: string;
  type?: TransactionType;
  initialData?: PaginatedCardTransactions;
  dateRange?: DateRange;
}) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    page,
    search,
    type,
    from:
      fromDate &&
      toDate &&
      formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to:
      fromDate &&
      toDate &&
      formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
  });
  return useFetch<PaginatedCardTransactions>(
    ApiEndpoints.transactions,
    params,
    { initialData },
  );
};
