import { Tag, Text } from "@axazara/raiton-atoms";
import { TickCircleFill, CloseCircleFill } from "@axazara/raiton-icons";

export function CardStatusBadge({ status }: { status: boolean }) {
  if (status)
    return (
      <Tag
        className="text-p-01 normal-case bg-success-90 py-3"
        size="lg"
        leftSection={<TickCircleFill className="w-4 h-4 text-success-30" />}
      >
        <Text component="span" className="text-success-30">
          Active
        </Text>
      </Tag>
    );

  return (
    <Tag
      className="text-p-01 normal-case bg-critical-50 py-3"
      size="lg"
      leftSection={<CloseCircleFill className="w-4 h-4 text-white" />}
    >
      <Text component="span" className="text-white">
        Inactive
      </Text>
    </Tag>
  );
}
