import { CardElements } from "@/components";
import { appPaths } from "@/configs";
import {
  ActionIcon,
  Avatar,
  Box,
  Table,
  Tag,
  Text,
} from "@axazara/raiton-atoms";
import {
  SearchStatusOutline,
  TickCircleFill,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import { Link } from "react-router-dom";
import { Card, formatDate } from "utilities";

export function CardsTableRow({ card }: { card: Card }) {
  return (
    <Table.Row key={card.id} className="border-b border-neutral-90">
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {card.id}
      </Table.Cell>
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        <CardElements
          last_four_digits={card.last_four_digits}
          label={card.label}
        />
      </Table.Cell>
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {card.balance_formatted}
      </Table.Cell>
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {card.is_active ? (
          <Tag
            className="text-p-01 normal-case bg-success-90 py-3"
            size="lg"
            leftSection={<TickCircleFill className="w-4 h-4 text-success-30" />}
          >
            <Text component="span" className="text-success-30">
              Active
            </Text>
          </Tag>
        ) : (
          <Tag
            className="text-p-01 normal-case bg-critical-50 py-3"
            size="lg"
            leftSection={<CloseCircleFill className="w-4 h-4 text-white" />}
          >
            <Text component="span" className="text-white">
              Inactive
            </Text>
          </Tag>
        )}
      </Table.Cell>
      <Table.Cell>
        <Box className="md:flex-row flex flex-col items-center space-x-4">
          <Avatar src={card?.user?.profile_photo_url} size={48} />
          <Box>
            <Text className="text-p-01-semibold text-neutral-20">
              {card?.user?.first_name} {card?.user?.last_name}
            </Text>
            <Text className="text-caption-medium text-neutral-60">
              {card?.user?.email}
            </Text>
          </Box>
        </Box>
      </Table.Cell>
      <Table.Cell className="text-p-01 text-neutral-40">
        {card.created_at &&
          formatDate(card.created_at, "en", "D MMM YYYY, HH:mm")}
      </Table.Cell>

      <Table.Cell>
        <ActionIcon
          component={Link}
          to={`/${appPaths.cardDetails.name}/${card.id}`}
          size="xl"
          radius={100}
          className="p-0.5"
        >
          <SearchStatusOutline className="w-6 h-6 text-primary-50" />
        </ActionIcon>
      </Table.Cell>
    </Table.Row>
  );
}
