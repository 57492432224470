import { Navigate, Route, Routes } from "react-router-dom";
import { AuthLayout, PrivateOutlet, RootLayout } from "@/layouts";
import { appPaths } from "@/configs";
import {
  CardDetails,
  Cards,
  DiscountCodeDetails,
  DiscountCodes,
  ExchangeRates,
  Invitation,
  Login,
  NotFound,
  PurchaseDetails,
  Purchases,
  RefillDetails,
  Refills,
  StaffDetailsPage,
  Staffs,
  Transactions,
  Users,
  UsersDetails,
} from "@/features";

export function AppRoutes() {
  return (
    <Routes>
      <Route path={appPaths.root} element={<PrivateOutlet />}>
        <Route path={appPaths.root} element={<RootLayout />}>
          <Route index element={<Navigate to={appPaths.users.path} />} />

          <Route path={appPaths.users.name}>
            <Route index element={<Users />} />
            <Route path=":id" element={<UsersDetails />} />
          </Route>

          <Route path={appPaths.cards.name}>
            <Route index element={<Cards />} />
            <Route path=":id" element={<CardDetails />} />
          </Route>

          <Route path={appPaths.refills.name}>
            <Route index element={<Refills />} />
            <Route path=":id" element={<RefillDetails />} />
          </Route>

          <Route path={appPaths.purchases.name}>
            <Route index element={<Purchases />} />
            <Route path=":id" element={<PurchaseDetails />} />
          </Route>

          <Route path={appPaths.discountCodes.name}>
            <Route index element={<DiscountCodes />} />
            <Route path=":id" element={<DiscountCodeDetails />} />
          </Route>
          <Route
            path={appPaths.exchangeRate.name}
            element={<ExchangeRates />}
          />

          <Route path={appPaths.staffs.name}>
            <Route index element={<Staffs />} />
            <Route path=":id" element={<StaffDetailsPage />} />
          </Route>
          <Route path={appPaths.transactions.path} element={<Transactions />} />
        </Route>
      </Route>
      <Route path={appPaths.auth} element={<AuthLayout />}>
        <Route path={appPaths.login.name} element={<Login />} index />
      </Route>

      <Route path={appPaths.invitation.path} element={<Invitation />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
