import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/en";
import "dayjs/locale/fr";

dayjs.extend(localizedFormat);

export const formatDate = (
  date: string,
  locale: string | undefined = "fr",
  format: string | undefined = "LL",
) => {
  if (!["en", "fr"].includes(locale)) throw "Invalid locale";
  return dayjs(date, { locale: locale }).format(format);
};

dayjs.extend(relativeTime);

export const dateToNow = (date: string) => {
  return dayjs().to(dayjs(date));
};
