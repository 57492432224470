import { useDebouncedValue } from "@axazara/raiton-utils";
import React from "react";
import { PaginatedRefills } from "utilities";
import { useDynamicURLSearchParams } from "@/hooks";
import { useGetRefills } from "@/features";

export function useRefills({ initialData }: { initialData: PaginatedRefills }) {
  const stateData = [
    { label: "All", value: "all", color: "bg-neutral-50" },
    { label: "Initiated", value: "initiated", color: "bg-primary-40" },
    {
      label: "Pending payment",
      value: "pending_payment",
      color: "bg-secondary-40",
    },
    {
      label: "Pending refill",
      value: "pending_refill",
      color: "bg-secondary-40",
    },
    { label: "Success", value: "success", color: "bg-success-30" },
    {
      label: "Success payment",
      value: "payment_success",
      color: "bg-success-30",
    },
    { label: "Failed", value: "failed", color: "bg-critical-30" },
    {
      label: "Payment failed",
      value: "payment_failed",
      color: "bg-critical-30",
    },
  ];

  const { params: urlParams, setParams: setUrlParams } =
    useDynamicURLSearchParams<{
      search: string;
      page: string;
      status: string;
    }>({ search: "", page: "1", status: "all" });

  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const [opened, setOpened] = React.useState(false);

  const [state, setState] = React.useState<any | null>(
    stateData.find((state) => state.value === urlParams.status) || stateData[0],
  );

  const { data: refills, isLoading } = useGetRefills({
    page,
    search: debouncedSearch,
    initialData,
    status: state?.value || "all",
  });

  return {
    refills,
    isLoading,

    page,
    setPage,
    searchQuery,
    setSearchQuery,
    debouncedSearch,
    setState,
    opened,
    setUrlParams,
    setOpened,
    stateData,
    state,
  };
}
