import React from "react";
import { useSetPageMeta } from "@/hooks";
import {
  CardOutline,
  SearchNormal1Outline,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import { useGetCards } from "../services";
import { Box, InputField, LoadingOverlay } from "@axazara/raiton-atoms";
import { EmptyState, PaginationControls } from "@/components";
import { useDebouncedValue } from "@axazara/raiton-utils";
import { CardsTable } from "../components";

function CardsPageRenderer() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);

  const { data: cards, isLoading } = useGetCards({
    page,
    search: debouncedSearch,
  });

  return (
    <Box>
      <Box className="pt-4">
        <Box className="flex flex-row w-full justify-between mb-5">
          <Box className="flex gap-2 w-full">
            <InputField
              classNames={{ input: "rounded-xl" }}
              radius={12}
              placeholder="Search card"
              size="xs"
              icon={<SearchNormal1Outline className="w-5 h-5" />}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full"
              value={searchQuery}
              rightSection={
                <Box>
                  {debouncedSearch ? (
                    <CloseCircleFill
                      className="w-4 h-4 text-neutral-40"
                      onClick={() => {
                        setSearchQuery("");
                      }}
                    />
                  ) : null}
                </Box>
              }
            />
          </Box>
        </Box>
        <Box>
          <Box className="relative">
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ color: "secondary" }}
            />
            {cards?.data && (
              <>
                <CardsTable cards={cards.data} />
                <PaginationControls
                  page={page}
                  pagination={cards?.pagination}
                  onPageChange={setPage}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function Cards() {
  useSetPageMeta({
    title: "Cards",
    icon: CardOutline,
  });

  const { data: cardsData, isLoading } = useGetCards({ page: 1 });

  if (isLoading) return <LoadingOverlay visible />;

  if (cardsData?.data && cardsData.data.length > 0)
    return <CardsPageRenderer />;

  return <EmptyState title="No Cards yet" />;
}
