import { Navigate, useParams } from "react-router-dom";
import { appPaths } from "@/configs";
import { InvitationView } from "./components";

export function Invitation() {
  const { token } = useParams();

  if (!token) return <Navigate to={appPaths.login.path} />;

  return <InvitationView token={token} />;
}
