import { appPaths } from "@/configs";
import {
  DiscountCodeStatusBadge,
  useUpdateDiscountCodeEnable,
} from "@/features";
import { showSuccessNotification, showErrorNotification } from "@/utils";
import {
  ActionIcon,
  Box,
  Switch,
  Table,
  Text,
  Tooltip,
} from "@axazara/raiton-atoms";
import {
  SearchStatusOutline,
  CopySuccessOutline,
  CopyOutline,
  AddOutline,
} from "@axazara/raiton-icons";
import { useClipboard } from "@axazara/raiton-utils";
import { Link } from "react-router-dom";
import { DiscountCode, DiscountCodes, formatDate } from "utilities";

export function DiscountCodesTable({
  discountCodes,
  refetch,
}: {
  discountCodes: DiscountCodes;
  refetch?: any;
}) {
  return (
    <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral font-poppins">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Id
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Code
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Value
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Create at
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Status
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Enabled
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
            View
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-neutral-80 font-poppins">
          {discountCodes?.map((discountCode) => (
            <DiscountCodesTableRow
              discountCode={discountCode}
              key={discountCode.id}
              refetch={refetch}
            />
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
}

function DiscountCodesTableRow({
  discountCode,
  refetch,
}: {
  discountCode: DiscountCode;
  refetch: any;
}) {
  const { copy: copyCode, copied: CodeCopied } = useClipboard({ timeout: 500 });

  const useChangeDiscountCodeEnabledMutation = useUpdateDiscountCodeEnable({
    id: discountCode.id as string,
  });

  async function handleDiscountCodeChangeEnabled(value: boolean) {
    try {
      const response = await useChangeDiscountCodeEnabledMutation.mutateAsync({
        is_enabled: value,
      });
      if (refetch) refetch();
      showSuccessNotification((response as any).message);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }

  return (
    <Table.Row key={discountCode.id} className="border-b border-neutral-90">
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {discountCode.id}
      </Table.Cell>

      <Table.Cell>
        <Box className="flex flex-row items-center">
          <Text className="text-neutral-30 text-p-01-semibold">
            {discountCode.code}
          </Text>
          <Tooltip label={<Text>{CodeCopied ? "copied" : "copy"}</Text>}>
            <Box
              onClick={() => copyCode(discountCode.code)}
              className="cursor-pointer"
            >
              {CodeCopied ? (
                <CopySuccessOutline className="w-4 h-4 text-primary-40 ml-2" />
              ) : (
                <CopyOutline className="w-4 h-4 text-primary-40 ml-2" />
              )}
            </Box>
          </Tooltip>
        </Box>
      </Table.Cell>
      <Table.Cell className="text-neutral-40 text-p-01-medium">
        {discountCode.value}
      </Table.Cell>

      <Table.Cell className="text-p-01 text-neutral-40">
        {discountCode.created_at &&
          formatDate(discountCode.created_at, "en", "D MMM YYYY, HH:mm:ss")}
      </Table.Cell>
      <Table.Cell className="text-p-01 text-neutral-40">
        <DiscountCodeStatusBadge is_expired={discountCode.is_expired} />
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01-medium text-neutral-40">
          <Switch
            styles={(theme) => ({
              track: {
                minWidth: 52,
                borderRadius: 24,
                backgroundColor: theme.colors.neutral[8],
                border: 0,
              },
              root: {
                width: 52,
              },
              thumb: {
                border: "none",
                left: 4,
                "input:checked + * > &": {
                  left: "calc(100% - 32px)",
                },
              },
            })}
            size="xl"
            checked={discountCode.is_enabled}
            onChange={({ currentTarget }) =>
              handleDiscountCodeChangeEnabled(currentTarget.checked)
            }
            thumbIcon={
              discountCode.is_enabled ? (
                <Box>
                  <img src="/images/mark.svg" alt="mark_icons" />
                </Box>
              ) : (
                <Box style={{ transform: "rotate(45deg)" }}>
                  <AddOutline className=" text-neutral-30 w-6 h-6" />
                </Box>
              )
            }
          />
        </Text>
      </Table.Cell>
      <Table.Cell className="flex justify-end">
        <ActionIcon
          component={Link}
          to={`/${appPaths.discountCodes.name}/${discountCode.id}`}
          size="xl"
          radius={100}
          className="p-0.5"
        >
          <SearchStatusOutline className="w-6 h-6 text-primary-50" />
        </ActionIcon>
      </Table.Cell>
    </Table.Row>
  );
}
