import { EmptyState, PaginationControls } from "@/components";
import { useSetPageMeta } from "@/hooks";
import {
  Box,
  Button,
  InputField,
  LoadingOverlay,
  Text,
} from "@axazara/raiton-atoms";
import {
  RefreshOutline,
  SearchNormal1Outline,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import { openModal } from "@axazara/raiton-molecules";
import { useGetDiscountCodes } from "../services";
import { useDebouncedValue } from "@axazara/raiton-utils";
import React from "react";
import { AddDiscountCode, DiscountCodesTable } from "@/features";

function DiscountCodesPageRenderer() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);
  const {
    data: discountCodes,
    isLoading,
    refetch,
  } = useGetDiscountCodes({ page, search: debouncedSearch });

  return (
    <Box>
      <Box className="pt-4">
        <Box className="flex flex-row w-full justify-between mb-5">
          <Box className="flex gap-2 w-full">
            <InputField
              classNames={{ input: "rounded-xl" }}
              radius={12}
              placeholder="Search discount code"
              size="xs"
              icon={<SearchNormal1Outline className="w-5 h-5" />}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full"
              value={searchQuery}
              rightSection={
                <Box>
                  {debouncedSearch ? (
                    <CloseCircleFill
                      className="w-4 h-4 text-neutral-40"
                      onClick={() => {
                        setSearchQuery("");
                      }}
                    />
                  ) : null}
                </Box>
              }
            />
            <Button
              onClick={() =>
                openModal({
                  children: <AddDiscountCode />,
                  size: "md",
                  centered: true,
                  title: (
                    <Text className="text-heading-05-bold text-neutral-30">
                      Add discount Code
                    </Text>
                  ),
                })
              }
              size="xs"
              className="text-p-01-semibold"
            >
              Add discount code
            </Button>
          </Box>
        </Box>
        <Box>
          <Box className="relative">
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ color: "secondary" }}
            />
            {discountCodes?.data && (
              <>
                <DiscountCodesTable
                  discountCodes={discountCodes.data}
                  refetch={refetch}
                />

                <PaginationControls
                  page={page}
                  pagination={discountCodes?.pagination}
                  onPageChange={setPage}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function DiscountCodes() {
  useSetPageMeta({
    title: "Discount codes",
    icon: RefreshOutline,
  });

  const { data: discountCodes, isLoading } = useGetDiscountCodes({ page: 1 });
  if (isLoading) return <LoadingOverlay visible />;

  if (discountCodes?.data && discountCodes?.data.length > 0)
    return <DiscountCodesPageRenderer />;

  return (
    <Box className="h-full grid place-content-center">
      <Box className="flex flex-col justify-center items-center">
        <EmptyState
          title="No refill code yet"
          primaryAction={
            <Button
              onClick={() =>
                openModal({
                  children: <AddDiscountCode />,
                  size: "md",
                  centered: true,
                  title: (
                    <Text className="text-heading-05-bold text-neutral-30">
                      Add discount Code
                    </Text>
                  ),
                })
              }
              size="xs"
              className="text-p-01-semibold"
            >
              Add discount code
            </Button>
          }
        />
      </Box>
    </Box>
  );
}
