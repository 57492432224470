import React from "react";
import { Box, Surface, Text } from "@axazara/raiton-atoms";
import animationData from "@/assets/empty_state.json";
import { useLottie } from "lottie-react";

interface EmptyStateProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  primaryAction?: React.ReactNode;
  secondaryAction?: React.ReactNode;
}

export function EmptyState({
  title,
  description,
  primaryAction,
  secondaryAction,
}: EmptyStateProps) {
  const options = {
    animationData: animationData,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <Box className="grid place-items-center h-full w-full">
      <Surface className="max-w-4xl mx-auto flex justify-center items-center">
        <Box className="flex flex-col items-center justify-center">
          <Box className="mb-5 w-40 h-40">{View}</Box>
          <Box className="flex flex-col items-center justify-center mb-6">
            <Text className="text-heading-06-bold mb-4">{title}</Text>
            <Text className="w-1/2 text-center text-p-01 text-neutral-40">
              {description}
            </Text>
          </Box>
          <Box className="flex flex-row items-center space-x-5">
            {primaryAction}
            {secondaryAction}
          </Box>
        </Box>
      </Surface>
    </Box>
  );
}
