import { Box, Table } from "@axazara/raiton-atoms";
import { Rates } from "utilities";
import { ExchangeRatesTableRow } from "./ExchangeRatesTableRow";

export function ExchangeRatesTable({
  rates,
  refetch,
}: {
  rates: Rates;
  refetch: any;
}) {
  return (
    <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            From currency
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            To currency
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Correction rate
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Rate
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Corrected rate
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">
            Edit
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-neutral-80">
          {rates?.map((rate) => {
            return (
              <ExchangeRatesTableRow
                rate={rate}
                key={rate.id}
                refetch={refetch}
              />
            );
          })}
        </Table.Body>
      </Table>
    </Box>
  );
}
