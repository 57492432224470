import { useSetPageMeta } from "@/hooks";
import { CardAddOutline } from "@axazara/raiton-icons";
import { useGetPurchases, usePurchases } from "../services";
import { Box, LoadingOverlay } from "@axazara/raiton-atoms";
import { PaginatedPurchases } from "utilities";
import {
  EmptyState,
  PaginationControls,
  PurchasesFilterMenu,
} from "@/components";
import { PurchasesTable } from "@/features";

function PurchasesPageRenderer({
  initialData,
}: {
  initialData: PaginatedPurchases;
}) {
  const {
    purchases,
    isLoading,

    page,
    setPage,
    searchQuery,
    setSearchQuery,
    debouncedSearch,
    setState,

    opened,
    setUrlParams,
    setOpened,
    stateData,
    state,

    openedType,
    setOpenedType,
    setType,
    type,
    typeData,
  } = usePurchases({
    initialData,
  });

  return (
    <Box>
      <Box className="pt-4">
        <PurchasesFilterMenu
          stateData={stateData}
          state={state}
          opened={opened}
          setState={setState}
          setOpened={setOpened}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          debouncedSearch={debouncedSearch}
          setUrlParams={setUrlParams}
          openedType={openedType}
          setOpenedType={setOpenedType}
          setType={setType}
          type={type}
          typeData={typeData}
        />
        <Box>
          <Box className="relative">
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ color: "secondary" }}
            />
            {purchases?.data && (
              <>
                <PurchasesTable purchases={purchases?.data} />
                <PaginationControls
                  page={page}
                  pagination={purchases?.pagination}
                  onPageChange={setPage}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function Purchases() {
  useSetPageMeta({
    title: "Purchases",
    icon: CardAddOutline,
  });
  const { data: purchasesData, isLoading } = useGetPurchases({ page: 1 });

  if (isLoading) return <LoadingOverlay visible />;

  if (purchasesData?.data && purchasesData?.data.length > 0)
    return <PurchasesPageRenderer initialData={purchasesData} />;

  return <EmptyState title="No purchase yet" />;
}
