import { ApiEndpoints } from "@/configs";
import { useFetch, usePost, useUpdate } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { PaginatedRates, RatePayload, RateResponse } from "utilities";
import { useState, useEffect } from "react";

export const useGetRates = ({
  page,
  search,
  initialData,
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedRates;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });

  return useFetch<PaginatedRates>(ApiEndpoints.rates, params, { initialData });
};

export const useCreateRate = () =>
  usePost<RatePayload, RateResponse>(ApiEndpoints.rates);

export const useUpdateRate = (id: string) =>
  useUpdate<Partial<RatePayload>, RateResponse>(`${ApiEndpoints.rates}/${id}`);

export function useCorrectionRateCalculator(
  correctionRateValue: any,
  baseRate: any,
) {
  const [correctionRate, setCorrectionRate] = useState(null);

  useEffect(() => {
    const calculateCorrectionRate = () => {
      const A = baseRate * (correctionRateValue / 100);
      return A + baseRate;
    };

    const result = calculateCorrectionRate();
    setCorrectionRate(result);
  }, [correctionRateValue, baseRate]);

  return correctionRate;
}
