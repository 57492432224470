import { Box, Table } from "@axazara/raiton-atoms";
import { Cards } from "utilities";
import { CardsTableRow } from "./CardsTableRow";

export function CardsTable({ cards }: { cards: Cards }) {
  return (
    <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral font-poppins">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Id
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Card
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Balance
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Statut
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            User
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            Create at
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            View
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-neutral-80 font-poppins">
          {cards.map((card) => {
            return <CardsTableRow card={card} key={card.id} />;
          })}
        </Table.Body>
      </Table>
    </Box>
  );
}
