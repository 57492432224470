import * as React from "react";

import { Navbar, Box } from "@axazara/raiton-atoms";

import {
  Profile2userOutline,
  PeopleOutline,
  CardAddOutline,
  CardReceiveOutline,
  CardOutline,
  RefreshOutline,
  ArrangeSquare2Outline,
  RepeatCircleOutline,
} from "@axazara/raiton-icons";
import { PersistentStorage } from "@/utils";
import { useHover } from "@axazara/raiton-utils";
import { SidebarLink } from "./SidebarLink";
import { SidebarFlapper } from "./SidebarFlapper";
import AppLogo from "./AppLogo";
import { AccessControl } from "@/context";
import { appPaths } from "@/configs";
import { StaffPermissions } from "utilities";

interface SidebarProps {
  isSidebarOpened: boolean;
  open: (callback?: () => void) => void;
  onClose: (callback?: () => void) => void;
}

export function Sidebar({ isSidebarOpened, onClose, open }: SidebarProps) {
  const sidebarRef = React.useRef<HTMLDivElement | null>(null);
  const isSidebarHover = useHover(sidebarRef);

  React.useEffect(() => {
    if (PersistentStorage.getData("closed_sidebar")) {
      if (!isSidebarOpened && isSidebarHover) {
        expandSidebar();
      } else {
        collapseSidebar();
      }
    }
  }, [isSidebarHover]);

  function expandSidebar() {
    open();
  }

  function collapseSidebar() {
    onClose(() => {
      PersistentStorage.setData("closed_sidebar", 1);
    });
  }

  return (
    <Navbar
      width={{ base: isSidebarOpened ? 276 : 90 }}
      className="pt-4 flex flex-col justify-start relative transition-all duration-300"
    >
      <AppLogo opened={isSidebarOpened} />

      <Navbar
        ref={sidebarRef}
        className="z-0 relative h-full"
        withBorder={false}
        styles={(theme) => ({
          root: {
            paddingLeft: 12,
            paddingRight: 12,
            paddingBottom: 90,
            maxHeight: "calc(100% - 50px)",
            height: "auto",
            overflowY: "hidden",
            scrollbarGutter: "stable",
            scrollbarWidth: "thin",
            "&:hover": {
              overflowY: "auto",
              scrollbarWidth: "thin",
            },
            "::-webkit-scrollbar": {
              width: 2,
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: theme.colors.neutral[9],
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: theme.colors.neutral[6],
            },
          },
        })}
      >
        <Box className="space-y-2">
          <SidebarLink
            to={appPaths.users.path}
            icon={<Profile2userOutline className="w-5 h-5" />}
            labelKey="Users"
            opened={isSidebarOpened}
          />

          <SidebarLink
            to={appPaths.cards.path}
            icon={<CardOutline className="w-5 h-5" />}
            labelKey="Cards"
            opened={isSidebarOpened}
          />

          <SidebarLink
            to={appPaths.refills.path}
            icon={<CardReceiveOutline className="w-5 h-5" />}
            labelKey="Refills"
            opened={isSidebarOpened}
          />

          <SidebarLink
            to={appPaths.discountCodes.path}
            icon={<RefreshOutline className="w-5 h-5" />}
            labelKey="Discounts codes"
            opened={isSidebarOpened}
          />
          <SidebarLink
            to={appPaths.exchangeRate.path}
            icon={<RepeatCircleOutline className="w-5 h-5" />}
            labelKey="Exchange rates"
            opened={isSidebarOpened}
          />

          <SidebarLink
            to={appPaths.purchases.path}
            icon={<CardAddOutline className="w-5 h-5" />}
            labelKey="Purchases"
            opened={isSidebarOpened}
          />
          <SidebarLink
            to={appPaths.transactions.path}
            icon={<ArrangeSquare2Outline className="w-5 h-5" />}
            labelKey="Transactions"
            opened={isSidebarOpened}
          />

          <AccessControl
            permissions={[
              StaffPermissions.CREATE_STAFFS,
              StaffPermissions.UPDATE_STAFFS,
              StaffPermissions.READ_STAFFS,
            ]}
          >
            <SidebarLink
              opened={isSidebarOpened}
              labelKey="Staffs"
              to={appPaths.staffs.path}
              icon={<PeopleOutline className="w-5 h-5" />}
            />
          </AccessControl>
        </Box>
      </Navbar>

      <SidebarFlapper
        opened={isSidebarOpened}
        collapseSidebar={collapseSidebar}
        expandSidebar={expandSidebar}
      />
    </Navbar>
  );
}
